import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import Label from '../Label/Label'
import Grid from '../Grid/Grid'

import {
  lightGray,
  normalText,
  smallText
} from '../../Styles/settings/Constants'

const DisplayDataSeller = ({
  seller,
  border,
  planId,
  isFavorite,
  setFavorite,
  deleteFavorite,
  showFavorites
}) => {
  const [isHover, setIsHover] = useState(false)

  const name =
    seller.type === 'individual'
      ? `${seller.first_name} ${seller.last_name}`
      : `${seller.statement_descriptor || ''}`
  const document =
    seller.type === 'individual'
      ? `CPF: ${seller.taxpayer_id.toCPF()}`
      : `CNPJ: ${seller.ein?.toCnpj()}`

  return (
    <Container
      border={border}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      hasPaddingRight={isHover || isFavorite}
    >
      <Grid.Row noMargin>
        <Grid.Col cols={planId && planId === seller.planId ? 12 : 16}>
          <Header>{name && name.toLowerCase()}</Header>
        </Grid.Col>
        {planId && planId === seller.planId && (
          <Grid.Col cols={4}>
            <Tag>
              <Label fontSize={'0.8rem'}>Já associado</Label>
            </Tag>
          </Grid.Col>
        )}
      </Grid.Row>
      {seller.type === 'business' && (
        <Text>
          {seller.business_name && seller.business_name.toLowerCase()}
        </Text>
      )}
      <Text className='document' disabled={planId && planId === seller.planId}>
        {document}
      </Text>
      {showFavorites && isFavorite && (
        <FavoriteIcon
          src={`/assets/icons/actions/like-filled.svg`}
          onClick={(e) => {
            e.stopPropagation()
            deleteFavorite(seller)
          }}
        />
      )}
      {showFavorites && !isFavorite && isHover && (
        <FavoriteIcon
          src={`/assets/icons/actions/like.svg`}
          onClick={(e) => {
            e.stopPropagation()
            setFavorite(seller)
          }}
        />
      )}
    </Container>
  )
}

DisplayDataSeller.propTypes = {
  seller: PropTypes.object,
  border: PropTypes.bool,
  planId: PropTypes.string,
  isFavorite: PropTypes.bool,
  setFavorite: PropTypes.func,
  deleteFavorite: PropTypes.func,
  showFavorites: PropTypes.bool
}

const Container = styled.div`
  padding: 1rem 1.4rem;
  width: 100%;

  ${({ hasPaddingRight }) =>
    hasPaddingRight &&
    css`
      padding-right: 4rem;
    `}

  ${({ border }) =>
    border &&
    css`
      border-left: 5px solid ${(props) => props.theme.color};
      padding-left: 5px;
    `}
`

const Tag = styled.div`
  border: 1px solid ${(props) => props.theme.color};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  height: 15px;
  width: 64px;
`

const Header = styled.p`
  font-size: ${normalText};
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${lightGray};
    `}
`

const Text = styled.p`
  font-size: ${smallText};
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${lightGray};
    `}
`

const FavoriteIcon = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 99999;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default DisplayDataSeller
