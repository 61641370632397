import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import ReactSelect, { components } from 'react-select'
import ReactSVG from 'react-svg'

import { smallText, normalText, white, red, darkGray, darkerGray, placeholder, bigText, textBold } from '../../Styles/settings/Constants'

// COMPONENTES INTERNOS DO SELECT PARA CUSTOMIZAR APARENCIA
const DropdownIndicator = props => (
  !props.isDisabled
    ? <components.DropdownIndicator {...props}>
      <Icon black={props.selectProps && props.selectProps.black} src='/assets/icons/arrow/arrow-down.svg' />
    </components.DropdownIndicator>
    : null
)

const Control = (props) => (
  <ControlComponent {...props}
    black={props.selectProps && props.selectProps.black}
    white={props.selectProps && props.selectProps.white}
  />
)

const IndicatorSeparator = ({ innerProps }) => (
  <SeparatorComponent {...innerProps} />
)

const SingleValue = ({ children, ...props }) => (
  <SingleValueComponent {...props} black={props.selectProps && props.selectProps.black}
  >
    {children}
  </SingleValueComponent>
)

const Placeholder = props => (
  <PlaceholderComponent {...props} black={props.selectProps && props.selectProps.black}
  />
)

const ValueContainer = ({ children, ...props }) => (
  <ValueComponent {...props}>
    {children}
  </ValueComponent>
)

const Input = props => (
  <InputComponent {...props} data-test='select-input' />
)

const Menu = props => (
  <MenuComponent {...props} data-test='select-menu'>{props.children}</MenuComponent>
)

const Option = props => (
  <OptionComponent {...props} data-test='select-option'>{props.children}</OptionComponent>
)

const Select = ({ value, disabled, label, options, placeholder, hasError, errorMessage, white, black, marginTop, viewMode, ...props }) => {
  const {
    color
  } = useSelector(({ custom }) => custom)
  const [focus, setFocus] = useState(false)
  return (
    <Container
      disabled={disabled}
      marginTop={marginTop}
      viewMode={viewMode}
      hasValue={!!value}
      data-test={`select-${label}`}
    >
      <ReactSelect
        options={options}
        isDisabled={disabled}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        value={value}
        white={white}
        black={black}
        components={{
          DropdownIndicator,
          Control,
          Input,
          IndicatorSeparator,
          SingleValue,
          Placeholder,
          ValueContainer,
          Menu,
          Option
        }}
        placeholder={placeholder || ''}
        theme={theme => ({
          ...theme,
          colors: {
            marketplace: color
          }
        })}
        {...props}
      />
      {label && <Label focus={focus || placeholder} value={value}>{label}</Label>}
      <LineAnimation focus={focus} hasError={hasError} />
      {hasError && <Error data-test='select-error' >{errorMessage}</Error>}
    </Container>
  )
}

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  white: PropTypes.bool,
  black: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string,
  errorMessage: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  props: PropTypes.object,
  marginTop: PropTypes.bool,
  viewMode: PropTypes.bool
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 2.2rem;
  font-size: ${bigText};
  margin-top: ${props => props.marginTop ? '1.8rem' : 0};

  ${({ viewMode, hasValue }) => (viewMode && !hasValue) && css`
    transform: translate3d(0, -70%, 0) scale(0.80);
  `}

  ${({ disabled, viewMode }) => (disabled && !viewMode) && css`
    opacity: 0.5;
  `}
`

const Label = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  color: ${darkGray};
  font-size: ${normalText};
  position: absolute;
  bottom: 6px;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;

  ${({ value, focus }) => (value || focus) && css`
    transform: translate3d(0, -70%, 0) scale(0.80);
  `}
`

const LineAnimation = styled.span`
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${props => props.theme.color};
  transition: 0.4s;

  ${({ focus }) => focus && css`
    width: 50%;
  `}

  ${({ hasError }) => hasError && css`
    background-color: ${red};
    width: 100%;
  `}
`

const Error = styled.span`
  color: ${red};
  font-size: ${smallText};
  position: absolute;
  top: 25px;
`

const Icon = styled(ReactSVG)`
  position: relative;
  top: 0.5rem;

  ${({ black }) => black && css`
    svg {
      fill: ${white}
    }
  `}
`

const ControlComponent = styled(components.Control)`
  border: none !important;
  background-color: ${props => props.white ? white : props.black ? darkerGray : 'transparent'} !important;
  border-radius: ${props => props.white || props.black ? '5px' : '0'} !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

  ${({ isFocused }) => isFocused && css`
    box-shadow: none !important;
    outline: none !important;
  `}

  ${({ isDisabled }) => isDisabled && css`
    border-bottom: none !important;
  `}
`

const PlaceholderComponent = styled(components.Placeholder)`
  color: ${props => props.black ? white : placeholder} !important;
  font-style: normal !important;
  font-size: ${normalText} !important;
  top: 26px !important;
`

const SeparatorComponent = styled.span`
  border: none !important;
`

const SingleValueComponent = styled(components.SingleValue)`
  color: ${props => props.black ? white : darkerGray} !important;
  top: 2.6rem !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: ${normalText} !important;
  line-height: 2rem !important;
`

const ValueComponent = styled(components.ValueContainer)`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

const InputComponent = styled(components.Input)`
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: ${normalText} !important;
  line-height: 2rem !important;
  color: ${darkGray} !important;

  > input {
    position: absolute;
    top: 56%;
    height: 1.4rem;
  }

`

const MenuComponent = styled(components.Menu)`
  z-index: 999999 !important;
  background: ${white} !important;
  box-shadow: 0px 7px 9px rgba(0, 0, 0, 0.07) !important;
  border-radius: 5px !important;
  border: none !important;
  padding: 0.75rem 0 !important;
`

const OptionComponent = styled(components.Option)`
  font-family: Roboto !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: ${normalText} !important;
  line-height: 2rem !important;
  color: ${darkGray} !important;
  margin: 0 !important;

  &:hover {
    position: relative !important;
    cursor: pointer !important;
    color: ${darkGray} !important;
    font-weight: ${textBold} !important;

    &:after {
      position: absolute !important;
      display: block !important;
      content: '' !important;
      width: 2px !important;
      height: 1.4rem !important;
      background-color: ${props => props.theme.colors.marketplace} !important;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  ${({ isSelected }) => isSelected && css`
    font-weight: ${textBold} !important;
    color: ${props => props.theme.colors.marketplace} !important;
  `}
`

export default Select
