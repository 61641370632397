import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import DisplayDataSeller from './DisplayDataSeller'

import { white, placeholder, lightGray, textBold } from '../../Styles/settings/Constants'
import { SelectListSellerLoader } from './SelectListSellerLoader'

const SelectListSeller = ({ items, context, action, planId, isLoading, ownDisplay, setFavorite, deleteFavorite, showFavorites, ...props }) => {
  return (
    <ListContainer ownDisplay={ownDisplay}>
      {ownDisplay && (
        <List>
          <ListItem data-tracking={`${props['data-tracking']} > allSellers`} onClick={(e) => action(e, null)}>
            <Text>Todos estabelecimentos</Text>
          </ListItem>
        </List>
      )}
      <List id='sellerScroll' style={{ overflow: 'scroll', overflowX: 'auto' }}>
        {isLoading && (
          <Container>
            <SelectListSellerLoader />
          </Container>
        )}
        {!isLoading && items.length === 0 && (
          <Container>
            <Empty
              title={`Não encontramos esse\nestabelecimento`}
              description={`Verifique se o nome ou CPF/CNPJ\nestão corretos e tente novamente`}
              iconSrc='/assets/icons/others/search-timeout.svg'
            />
          </Container>
        )}
        {!isLoading &&
          items.length > 0 &&
          items.map((item, index) => (
            <ListItem
              key={index}
              active={context && context.sellerId === item.id}
              onClick={planId && planId === item.planId ? undefined : (e) => action(e, item)}
              data-tracking={`${props['data-tracking']} > item`}
              data-test='select-seller-result-search-item'
            >
              <DisplayDataSeller
                seller={item}
                planId={planId}
                isFavorite={item.isFavorite}
                setFavorite={setFavorite}
                deleteFavorite={deleteFavorite}
                showFavorites={showFavorites}
              />
            </ListItem>
          ))}
      </List>
    </ListContainer>
  )
}

SelectListSeller.propTypes = {
  context: PropTypes.any,
  items: PropTypes.array,
  action: PropTypes.func,
  planId: PropTypes.string,
  isLoading: PropTypes.bool,
  'data-tracking': PropTypes.string,
  ownDisplay: PropTypes.bool,
  setFavorite: PropTypes.func,
  deleteFavorite: PropTypes.func,
  showFavorites: PropTypes.bool
}

SelectListSeller.defaultProps = {
  items: []
}

const ListContainer = styled.div`
  position: absolute;
  background-color: ${white};
  border-radius: 0 0 5px 5px;
  box-shadow: 0 3px 7px #ebebeb;
  padding: 3.5rem 1.4rem 1.4rem;
  top: -0.4rem;
  left: -1.4rem;
  width: calc(100% + 2.8rem);

  ${({ ownDisplay }) =>
    ownDisplay &&
    css`
    border: 1px solid ${lightGray}
    border-radius: 5px;
    left: 0;
    width: 100%;
    top: 0;
    padding-top: 10rem;
  `}
`

const Container = styled.div`
  width: 100%;
  background-color: ${white};
  max-height: 40vh;
`

const Empty = styled(EmptyState)`
  width: 100%;
`

const Text = styled.span`
  margin: 1rem 1.4rem;
  font-size: 1.4rem;
  line-height: 2rem;
`

const List = styled.ul`
  max-height: 315px;
  overflow: auto;
  list-style-type: none;
  padding-left: 0px;
  margin: 0 -1.4rem;
`

const ListItem = styled.li`
  width: 100%;
  display: flex;
  font-size: 1rem;
  color: ${(props) => (props.active ? props.theme.color : placeholder)};
  cursor: pointer;
  position: relative;

  font-weight: ${(props) => (props.active ? textBold : 400)};

  &:hover {
    font-weight: ${textBold};
  }

  & .document {
    font-weight: 400;
  }

  &:hover::after {
    position: absolute;
    content: '';
    display: block;
    background-color: ${(props) => props.theme.color};
    width: 2px;
    height: calc(100% - 20px);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`

export default SelectListSeller
