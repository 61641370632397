import { middleMargin, middlePadding, normalText, smallMargin, textBold, white } from '../../Styles/settings/Constants'
import styled, { css } from 'styled-components'

import Button from '../Button/ButtonDefault'
import Dots from '../Dots/Dots'
import PropTypes from 'prop-types'
import React from 'react'

const TableEditCell = ({ children, action, close, withAdjustPadding, isLoading, ...props }) => (
  isLoading ? <LabelDots><Dots outline /></LabelDots> : <Container>
    <Question withAdjustPadding={withAdjustPadding}>{children}</Question>
    <ButtonContainer>
      <ButtonRight
        data-tracking={`${props['data-tracking']} > NO`}
        data-test={`${props['data-tracking']}-no`}
        onClick={() => close()}
      >
        Não
      </ButtonRight>
      <ButtonLeft
        data-tracking={`${props['data-tracking']} > YES`}
        data-test={`${props['data-tracking']}-yes`}
        clean onClick={() => action()}
      >
        Sim
      </ButtonLeft>
    </ButtonContainer>
  </Container>
)

TableEditCell.propTypes = {
  children: PropTypes.any,
  action: PropTypes.func,
  close: PropTypes.func,
  withAdjustPadding: PropTypes.bool,
  'data-tracking': PropTypes.string,
  isLoading: PropTypes.bool
}

const LabelDots = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Container = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color};
  border-radius: 0 5px 5px 0;
  display: flex;
  padding: ${middlePadding};
`

const Question = styled.p`
  color: ${white};
  font-size: ${normalText};

  > strong {
    font-weight: ${textBold};
  }

  ${({ withAdjustPadding }) => withAdjustPadding && css`
    margin-left: ${middleMargin};
  `}
`

const ButtonContainer = styled.div`
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonRight = styled(Button)`
  border: 1px solid ${white};
  width: 70px;
  margin-right: ${smallMargin};
`

const ButtonLeft = styled(Button)`
  border-radius: 5px;
  width: 70px;
  border: 1px solid ${white};
  color: ${props => props.theme.color};
`

export default TableEditCell
