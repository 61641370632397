import styled from 'styled-components'
import { smallText, textBold, darkGray } from 'src/Styles/settings/Constants'
import { SketchPicker } from 'react-color'

const StyledSketchPicker = styled(SketchPicker)`
  .flexbox-fix {
    div {
      div {
        input {
          text-align: center;
        }

        span {
          width: 80%;
          color: ${darkGray} !important;
          font-weight: ${textBold};
          font-size: ${smallText} !important;
          text-transform: uppercase !important;
        }
      }
    }
  }
`

export default StyledSketchPicker
