import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const StatusBar = ({ color }) => <UIStatusBar color={color} />

const UIStatusBar = styled.div`
  width: 6px;
  height: 55px;
  background-color: ${props => props.color};
  position: absolute;
  left: 0;
`

StatusBar.propTypes = {
  color: PropTypes.string
}

export default StatusBar
