import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import SelectListBankCode from './SelectListBankCode'

import { placeholder, normalText, darkGray, red, lightGray, smallText } from '../../Styles/settings/Constants'
import { useDispatch, useSelector } from 'react-redux'
import { getBanks } from '../../Actions/banks'

const SelectBankCode = memo(({
  label,
  open,
  onClick,
  onSelect,
  onSearchChange,
  items,
  selected,
  hasError,
  errorMessage,
  onArrowClick
}) => {
  const { banks } = useSelector(({ banks }) => banks)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getBanks())
  }, [])

  const printLabel = value => {
    const selected = banks.find(i => i.code === value)
    return selected && `${selected.code} - ${selected.name}`
  }

  return (
    <Container>
      <SelectContainer>
        <LabelContainer onClick={onClick} data-test='select-bank-container'>
          <Selected open={open}>
            {printLabel(selected) || 'Selecione um banco'}
          </Selected>
          <Icon
            onClick={onArrowClick}
            src={open ? '/assets/icons/arrow/arrow-up.svg' : '/assets/icons/arrow/arrow-down.svg'}
          />
        </LabelContainer>
        {open && (
          <SelectListBankCode
            items={items}
            onSelect={onSelect}
            onClick={onClick}
            onSearchChange={onSearchChange}
            selected={selected}
          />
        )}
        <FloatingLabel open>{label}</FloatingLabel>
        <LineAnimation hasError={!open && hasError} />
      </SelectContainer>
      {hasError && <Error data-test='select-bank-error' >{errorMessage}</Error>}
    </Container>
  )
})

SelectBankCode.propTypes = {
  label: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  items: PropTypes.array,
  selected: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onArrowClick: PropTypes.func
}

const Container = styled.div`
  margin-top: 2rem;
`

const LabelContainer = styled.div``

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  font-size: 2rem;
  border-bottom: ${props => props.disabled ? '0' : '1px'} solid ${lightGray};

  & > * {
    z-index: 2;
  }
`

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${darkGray};
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: ${normalText};
  cursor: text;
  pointer-events: none;

  ${({ open }) => open && css`
    transform: translate3d(0, -95%, 0) scale(0.80);
  `}
`

const LineAnimation = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${props => props.theme.color};
  transition: 0.4s;

  ${({ open }) => open && css`
    width: 50%;
    transition: 0.4s;
  `}

  ${({ hasError }) => hasError && css`
    width: 100%;
    background-color: ${red};
    transition: 0.4s;
  `}
`

const Selected = styled.label`
  color: ${placeholder};
  width: 100%;
  border: none;
  font-size: ${normalText};
  display: block;
  height: 2.4rem;
  padding-right: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: -3px;
`

const Icon = styled(ReactSVG)`
  position: absolute;
  bottom: 0;
  right: 4px;
  height: 3rem;
`

const Error = styled.span`
  color: ${red};
  font-size: ${smallText};
  padding-top: 3rem;
`

export default SelectBankCode
