import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import styled from 'styled-components'

const SquareFormDetail = ({ success, final }) => {
  if (final) {
    return (
      <Icon src='/assets/icons/system/step-completed.svg' />
    )
  }
  return (
    <Icon src={success ? '/assets/icons/system/step-initial.svg' : '/assets/icons/system/step-default.svg'} />
  )
}

SquareFormDetail.propTypes = {
  success: PropTypes.bool,
  final: PropTypes.bool
}

const Icon = styled(ReactSVG)`
  svg {
    fill: ${props => props.theme.color};
  }
`

export default SquareFormDetail
