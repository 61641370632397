import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from '../Grid/Grid'

import {
  lightGray,
  normalText,
  smallText,
  smallerText,
  darkerGray
} from '../../Styles/settings/Constants'
import Label from '../Label/Label'

const DisplayMarketplaceInfo = ({
  marketplace,
  isParent,
  isFavorite,
  setFavorite,
  deleteFavorite,
  active
}) => {
  const [isHover, setIsHover] = useState(false)

  const name = marketplace.name || ''
  const document =
    marketplace && marketplace.ein ? `CNPJ: ${marketplace.ein?.toCnpj()}` : ''

  return (
    <Container
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      hasPaddingRight={isHover || isFavorite}
      isParent={isParent}
    >
      {isParent && (
        <Grid.Row smallMarginBottom>
          <Label fontSize={smallerText} bold>
            Matriz
          </Label>
        </Grid.Row>
      )}
      <Grid.Row noMargin>
        <Grid.Col cols={16}>
          <Label
            overflow
            bold={isHover || active}
            fontSize={normalText}
            color={!active && darkerGray}
          >
            {name}
          </Label>
        </Grid.Col>
      </Grid.Row>
      <Label
        overflow
        bold={isHover}
        fontSize={smallText}
        color={!active && darkerGray}
      >
        {document}
      </Label>
      {!isParent && isFavorite && (
        <FavoriteIcon
          src={`/assets/icons/actions/like-filled.svg`}
          onClick={(e) => {
            e.stopPropagation()
            deleteFavorite(marketplace)
          }}
        />
      )}
      {!isParent && !isFavorite && isHover && (
        <FavoriteIcon
          src={`/assets/icons/actions/like.svg`}
          onClick={(e) => {
            e.stopPropagation()
            setFavorite(marketplace)
          }}
        />
      )}
    </Container>
  )
}

DisplayMarketplaceInfo.propTypes = {
  marketplace: PropTypes.object,
  isParent: PropTypes.bool,
  isFavorite: PropTypes.bool,
  setFavorite: PropTypes.func,
  deleteFavorite: PropTypes.func,
  active: PropTypes.bool
}

const Container = styled.div`
  margin: 0 3rem;
  padding: 1.5rem 0;
  width: 100%;

  ${({ hasPaddingRight }) =>
    hasPaddingRight &&
    css`
      padding-right: 2.5rem;
    `}

  ${({ isParent }) =>
    isParent &&
    css`
      border-bottom: 1px solid ${lightGray};
    `}
`

const FavoriteIcon = styled(ReactSVG)`
  position: absolute;
  top: 50%;
  right: 30px;
  z-index: 99999;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default DisplayMarketplaceInfo
