import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SquareFormDetail from 'src/UIComponents/SquareFormDetail/SquareFormDetail'
import VerticalLine from 'src/UIComponents/VerticalLine/VerticalLine'

const Step = ({ success, final, successColor, isLastStep }) => {
  return (
    <Container>
      <SquareFormDetail success={success} final={final} />
      {!isLastStep && <VerticalLine successColor={successColor} />}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

Step.propTypes = {
  success: PropTypes.bool,
  final: PropTypes.bool,
  successColor: PropTypes.bool,
  isLastStep: PropTypes.bool
}

export default Step
