import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import Grid from 'src/UIComponents/Grid/Grid'
import CheckboxForm from 'src/UIComponents/Checkbox/CheckboxForm'
import styled, { css } from 'styled-components'

import { lightGray, smallPadding, spacePadding } from 'src/Styles/settings/Constants'

const SelectMultiply = ({ onChange, values, items, noLine }) => {
  const toggle = (value) => () => {
    if (values.has(value)) {
      values.delete(value)
    } else {
      values.add(value)
    }
    onChange(new Set(values))
  }

  const checkAll = (options) => {
    onChange(new Set([...values, ...options.map(({ value }) => value)]))
  }

  const uncheckAll = (options) => {
    options = options.map(({ value }) => value)
    onChange(new Set([...values].filter((value) => !options.includes(value))))
  }

  useEffect(() => {
    const options = items.reduce((acc, cur) => [...acc, ...cur.options], [])
    checkAll(options)
  }, [])

  const checkboxes = () => {
    return items.map((category, index) => {
      const { options, label } = category
      const anyCategoryOptionsSelected = options.find((option) => values.has(option.value))
      const allCategoryOptionsSelected = options.filter((option) => values.has(option.value)).length === options.length

      return (
        <Fragment key={index}>
          <ContainerList noLine={noLine}>
            <Grid.Col cols={16}>
              <CheckboxForm
                id={label}
                checked={allCategoryOptionsSelected}
                partialChecked={!allCategoryOptionsSelected && !!anyCategoryOptionsSelected}
                label={label}
                onClick={() => (!allCategoryOptionsSelected ? checkAll(options) : uncheckAll(options))}
                line
              />
            </Grid.Col>
          </ContainerList>
          <List noLine={noLine}>
            {options.map((option, key) => (
              <ListItem key={key}>
                <CheckboxForm id={key} checked={values.has(option.value)} label={option.name} onClick={toggle(option.value)} />
              </ListItem>
            ))}
          </List>
        </Fragment>
      )
    })
  }

  return <div>{checkboxes()}</div>
}

SelectMultiply.propTypes = {
  items: PropTypes.array,
  noLine: PropTypes.bool,
  onChange: PropTypes.func,
  values: PropTypes.array
}

const List = styled.ul`
  list-style-type: none;
  border-top: 2px solid ${lightGray};

  ${({ noLine }) =>
    noLine &&
    css`
      border-top: 0;
    `}
`

const ContainerList = styled.div`
  border-top: 2px solid ${lightGray};
  padding: ${smallPadding} 0;

  ${({ noLine }) =>
    noLine &&
    css`
      border-top: 0;
    `}
`

const ListItem = styled.li`
  width: 100%;
  padding: ${spacePadding} 0 ${spacePadding} 2.5rem;
  list-style-type: none;
`

export default SelectMultiply
