import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import ReactSvg from 'react-svg'

import { Beacon } from 'src/Components/OnboardingHints/Beacon'
import SelectListSeller from './SelectListSeller'
import {
  white,
  lightGray,
  placeholder,
  normalText,
  smallMargin,
  textMedium,
  red,
  smallText,
  textBold,
  darkGray
} from '../../Styles/settings/Constants'

const SelectSeller = (
  {
    display,
    value,
    items,
    action,
    onChange,
    onKeyDown,
    hasError,
    errorMessage,
    placeholder,
    border,
    planId,
    noBox,
    isLoading,
    loadMore,
    totalSellers,
    openDefault,
    context,
    onClose,
    uuid,
    showTotals,
    setFavorite,
    deleteFavorite,
    showHint,
    showFavorites,
    searchFunction,
    ...props
  }) => {
  const [open, setOpen] = useState(openDefault)

  SelectSeller[`${uuid}-handleClickOutside`] = () => {
    setOpen(false)
    onClose()
  }

  const handleAction = (e, item) => {
    action(e, item)
    setOpen(false)
    onClose()
  }
  useEffect(() => {
    setOpen(openDefault)
  }, [openDefault])
  return (
    <Fragment>
      <Container {...props} hasOwnDisplay={display} border={border} noBox={noBox} open={open}>
        {display}
        {showHint && !open && <Beacon hint='select-seller' wrapperProps={{ right: '60px' }} />}
        {
          ((display && open) || !display) &&
          <div className='search-input'>
            <InputSearch
              type='text'
              placeholder={!!display || !open ? placeholder : undefined}
              value={value}
              onClick={() => setOpen(true)}
              onChange={onChange}
              onKeyDown={onKeyDown}
              autofill='off'
              autocomplete='off'
              data-tracking={`${props['data-tracking']} > search`}
              data-test='select-seller-input-search'
              disabled={isLoading}
            />
            <Icon className='search-icon' src='/assets/icons/actions/search.svg' onClick={() => searchFunction(value)} />
            <LineAnimation hasError={!open && noBox && hasError} />
            {
              showTotals && value && !isLoading && (
                <span className='search-count'>
                  {totalSellers} {totalSellers > 1 ? 'resultados' : 'resultado'}
                </span>
              )
            }
          </div>
        }
        {open && (
          <SelectListSeller
            context={context}
            ownDisplay={!!display}
            items={items}
            action={handleAction}
            planId={planId}
            isLoading={isLoading}
            loadMore={loadMore}
            totalSellers={totalSellers}
            setFavorite={setFavorite}
            deleteFavorite={deleteFavorite}
            showFavorites={showFavorites}
            data-tracking={`${props['data-tracking']} > list`}
          />
        )}
      </Container>
      {hasError && <Error data-test='input-search-seller-error'>{errorMessage}</Error>}
    </Fragment>
  )
}

const clickOutsideConfig = {
  handleClickOutside: ({ props: { uuid } }) => SelectSeller[`${uuid}-handleClickOutside`]
}

SelectSeller.defaultProps = {
  placeholder: 'Buscar estabelecimento',
  onClick: () => { /* noop */ },
  onChange: () => { /* noop */ },
  onClose: () => { /* noop */ }
}

SelectSeller.propTypes = {
  context: PropTypes.any,
  display: PropTypes.node,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  border: PropTypes.bool,
  items: PropTypes.array,
  action: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  planId: PropTypes.string,
  noBox: PropTypes.bool,
  openDefault: PropTypes.bool,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  totalSellers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTotals: PropTypes.bool,
  uuid: PropTypes.string,
  setFavorite: PropTypes.func,
  'data-tracking': PropTypes.string,
  deleteFavorite: PropTypes.func,
  showHint: PropTypes.bool,
  showFavorites: PropTypes.bool,
  searchFunction: PropTypes.func
}

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ noBox }) => noBox ? 'transparent' : white};
  border-radius: 5px;
  transition: all 0.4s ease;
  padding-left: ${smallMargin};
  margin-top: 1.2rem;
  z-index: 9997;

  & .search-input {
    width: 100%;
  }
  
  & .search-icon:hover{
    cursor: pointer;
  }

  & .search-count {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
  }

  ${({ hasOwnDisplay }) => hasOwnDisplay && css`
    padding-left: 0;
    margin-top: 0;
    width: 26rem;

    & .search-input {
      position: absolute;
      margin: 5rem 1.4rem 1.4rem;
      top: 0;
      width: calc(100% - 2.8rem);
      border-bottom: solid 1px #EBEBEB;
      top: 10px;
    }
  `}

  ${({ border }) => border && css`
    border: 1px solid ${lightGray};
  `}

  ${({ noBox }) => noBox && css`
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    font-size: ${normalText};
    font-weight: ${textBold};
    color: ${darkGray};

    ${InputSearch} {
      background-color: transparent;
      border-bottom: 1px solid ${lightGray};
    }
  `}

  ${({ open }) => open && css`
    padding-left: 0;
    & > * {
      z-index: 3;
    }

    & > :last-child {
      z-index: 2;
    }
  `}
`

const LineAnimation = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${props => props.theme.color};
  transition: 0.4s;

  ${({ hasError }) => hasError && css`
    width: 100%;
    background-color: ${red};
    transition: 0.4s;
  `}
`

const InputSearch = styled.input`
  color: ${placeholder};
  height: 3rem;
  border: none;
  font-size: ${normalText};
  font-weight: ${textMedium};
  width: 100%;

  &::placeholder {
    color: ${placeholder};
    font-style: normal;
  }

  :focus {
    outline: none;
  }

  &:focus ~ ${LineAnimation} {
    width: 50%;
    transition: 0.4s;
  }

  &[disabled] {
    opacity: 0.5;
  }
`

const Icon = styled(ReactSvg)`
  width: fit-content;
  padding: 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

const Error = styled.span`
  color: ${red};
  font-size: ${smallText};
  display: flex;
  line-height: 16px;
  margin-top: 4px;
`

export default onClickOutside(SelectSeller, clickOutsideConfig)
