import React from 'react'
import ContentLoader from 'react-content-loader'

const TableLoader = (props) => (
  <ContentLoader
    speed={4}
    style={{ width: '100%', height: 'auto' }}
    width={800}
    height={160}
    viewBox='0 0 584 119'
    backgroundColor='#f4f4f4'
    foregroundColor='#dcdcdc'
    {...props}
  >
    <rect x='0' y='10' rx='2' ry='2' width='36' height='6' />
    <rect x='0' y='19' rx='2' ry='2' width='36' height='6' />
    <rect x='69' y='12' rx='2' ry='2' width='32' height='6' />
    <rect x='133' y='10' rx='2' ry='2' width='97' height='6' />
    <rect x='133' y='19' rx='2' ry='2' width='72' height='6' />
    <rect x='278' y='12' rx='2' ry='2' width='72' height='6' />
    <rect x='406' y='12' rx='2' ry='2' width='30' height='6' />
    <rect x='475' y='10' rx='2' ry='2' width='33' height='6' />
    <rect x='475' y='19' rx='2' ry='2' width='33' height='6' />
    <rect x='546' y='12' rx='2' ry='2' width='38' height='6' />
    <rect x='0' y='40' rx='2' ry='2' width='36' height='6' />
    <rect x='0' y='49' rx='2' ry='2' width='36' height='6' />
    <rect x='69' y='42' rx='2' ry='2' width='32' height='6' />
    <rect x='133' y='40' rx='2' ry='2' width='97' height='6' />
    <rect x='133' y='49' rx='2' ry='2' width='72' height='6' />
    <rect x='278' y='42' rx='2' ry='2' width='72' height='6' />
    <rect x='406' y='44' rx='2' ry='2' width='30' height='6' />
    <rect x='475' y='40' rx='2' ry='2' width='33' height='6' />
    <rect x='475' y='49' rx='2' ry='2' width='33' height='6' />
    <rect x='546' y='42' rx='2' ry='2' width='38' height='6' />
    <rect x='0' y='73' rx='2' ry='2' width='36' height='6' />
    <rect x='0' y='82' rx='2' ry='2' width='36' height='6' />
    <rect x='69' y='75' rx='2' ry='2' width='32' height='6' />
    <rect x='133' y='73' rx='2' ry='2' width='97' height='6' />
    <rect x='133' y='82' rx='2' ry='2' width='72' height='6' />
    <rect x='278' y='75' rx='2' ry='2' width='72' height='6' />
    <rect x='406' y='77' rx='2' ry='2' width='30' height='6' />
    <rect x='475' y='73' rx='2' ry='2' width='33' height='6' />
    <rect x='475' y='82' rx='2' ry='2' width='33' height='6' />
    <rect x='546' y='77' rx='2' ry='2' width='38' height='6' />
    <rect x='0' y='104' rx='2' ry='2' width='36' height='6' />
    <rect x='0' y='113' rx='2' ry='2' width='36' height='6' />
    <rect x='69' y='106' rx='2' ry='2' width='32' height='6' />
    <rect x='133' y='104' rx='2' ry='2' width='97' height='6' />
    <rect x='133' y='113' rx='2' ry='2' width='72' height='6' />
    <rect x='278' y='106' rx='2' ry='2' width='72' height='6' />
    <rect x='406' y='106' rx='2' ry='2' width='30' height='6' />
    <rect x='475' y='104' rx='2' ry='2' width='33' height='6' />
    <rect x='475' y='113' rx='2' ry='2' width='33' height='6' />
    <rect x='546' y='106' rx='2' ry='2' width='38' height='6' />
  </ContentLoader>
)

export default TableLoader
