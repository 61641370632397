import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'

import SelectListMarketplace from './SelectListMarketplace'
import {
  lightGray,
  normalText,
  smallMargin,
  darkGray,
  smallText,
  bigPadding,
  darkerGray
} from '../../Styles/settings/Constants'
import Label from '../Label/Label'
import { SearchInput } from '../SearchInput'
import { ChevronDown } from 'src/UIComponents/Chevron/Chevron'

const SelectMarketplace = ({
  currentMarketplace,
  enableSelect,
  items,
  handleOnChangeSearch,
  context,
  setFavorite,
  deleteFavorite,
  isOpen,
  setIsOpen,
  handleSelect,
  ...props
}) => {
  SelectMarketplace.handleClickOutside = () => {
    setIsOpen(false)
  }

  return (
    <Container {...props}>
      <CurrentMarketplace
        enableSelect={enableSelect}
        onClick={() => enableSelect && setIsOpen(!isOpen)}
        data-test='select-marketplace-access'
      >
        <Label color={darkGray} fontSize={normalText}>
          Você está em:
        </Label>
        <div>
          <Label bold color={darkerGray} fontSize={normalText}>
            <strong>
              {currentMarketplace && currentMarketplace.name
                ? currentMarketplace.name
                : ''}
            </strong>
          </Label>
          <Document color={darkerGray} fontSize={smallText}>
            {currentMarketplace && currentMarketplace.ein
              ? `CNPJ: ${currentMarketplace.ein?.toCnpj()}`
              : ''}
          </Document>
          {enableSelect && <ChevronDown />}
        </div>
      </CurrentMarketplace>
      {isOpen && (
        <SelectContainer open={isOpen}>
          <SearchContainer>
            <Label color={darkGray} fontSize={normalText}>
              Encontre um parceiro
            </Label>
            <SearchInput
              placeholder='Buscar marketplace'
              onChange={handleOnChangeSearch}
              data-tracking={`${props['data-tracking']} > search`}
            />
          </SearchContainer>
          <SelectListMarketplace
            handleSelect={handleSelect}
            context={context}
            currentMarketplace={currentMarketplace}
            items={items}
            setFavorite={setFavorite}
            deleteFavorite={deleteFavorite}
            data-tracking={`${props['data-tracking']} > list`}
          />
        </SelectContainer>
      )}
    </Container>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => SelectMarketplace.handleClickOutside
}

SelectMarketplace.defaultProps = {
  placeholder: 'Buscar'
}

SelectMarketplace.propTypes = {
  currentMarketplace: PropTypes.object,
  enableSelect: PropTypes.bool,
  context: PropTypes.any,
  items: PropTypes.array,
  handleOnChangeSearch: PropTypes.func,
  setFavorite: PropTypes.func,
  'data-tracking': PropTypes.string,
  deleteFavorite: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  handleSelect: PropTypes.func
}

const Container = styled.div`
  position: relative;
  z-index: 999;
`

const SelectContainer = styled.div`
  position: absolute;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  padding-left: ${smallMargin};
  width: 28rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.3);

  ${({ open }) =>
    open &&
    css`
      z-index: 999;
    `}
`

const SearchContainer = styled.div`
  justify-content: center;
  margin-left: -1rem;
  background-color: ${lightGray};
  padding: ${bigPadding};

  > form {
    margin-top: 8px;
  }
`

const CurrentMarketplace = styled.div`
  display: grid;

  ${({ enableSelect }) =>
    enableSelect &&
    css`
      cursor: pointer;
    `}

  &>div {
    display: flex;
    align-items: center;

    svg {
      width: 10px;
      height: 10px;
    }
  }
`

const Document = styled(Label)`
  margin: 0 10px;
`

export default onClickOutside(SelectMarketplace, clickOutsideConfig)
