import React from 'react'
import ContentLoader from 'react-content-loader'

const SellerHeaderInformationLoader = (props) => (
  <ContentLoader
    speed={0}
    width={470}
    height={84}
    viewBox='0 0 470 84'
    backgroundColor='#f4f4f4'
    foregroundColor='#dcdcdc'
    {...props}
  >
    <rect x='0' y='15' rx='3' ry='3' width='220' height='27' />
    <rect x='170' y='61' rx='3' ry='3' width='150' height='11' />
    <rect x='0' y='61' rx='3' ry='3' width='150' height='11' />
  </ContentLoader>
)

export default SellerHeaderInformationLoader
