import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { lightestGray, shadow } from '../../Styles/settings/Constants'

const Sidebar = ({ show, large, children, className }) => {
  return (
    <Wrapper>
      {show && <Overlay />}
      <SideBarContainer className={className} show={show} large={large}>
        { children }
      </SideBarContainer>
    </Wrapper>
  )
}

Sidebar.defaultProps = {
  show: false,
  large: false
}

Sidebar.propTypes = {
  show: PropTypes.bool,
  large: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  className: PropTypes.string
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
`

const Overlay = styled.div`
  background-color: rgba(0,0,0, 0.2);
  width: 100vw;
  height: 100vh;
`

const SideBarContainer = styled.div`
  width: ${({ large }) => large ? '1236px' : '460px'};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${lightestGray};
  box-shadow: 10px 0px 20px 0px ${shadow};
  transition: transform .5s;
  z-index: 99999;
  transform: translateX(110%);

  ${({ show }) => show && css`
    transform: translateX(0);
  `}

  &.modal-transition-entering {
    transform: translateX(110%);
  }

  &.modal-transition-entered {
    transform: translateX(0);
  }

  &.modal-transition-exiting {
    transform: translateX(0);
  }

  &.modal-transition-exited {
    transform: translateX(110%);
  }
`

export default Sidebar
