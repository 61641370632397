import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from 'src/UIComponents/Grid/Grid'
import { lightestGray } from '../../Styles/settings/Constants'

const SidebarContainer = ({ children }) => {
  return (
    <GridWrapper>
      { children }
    </GridWrapper>
  )
}

SidebarContainer.propTypes = {
  children: PropTypes.node
}

const GridWrapper = styled(Grid)`
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 65px);
  position: relative;
  margin-top: 65px;

  &::before, &::after {
    content: '';
    width: 100%;
    display: block;
    height: 10px;
    left: 0;
  }

  &::before {
    width: calc(100% + 60px);
    position: sticky;
    background: linear-gradient(0deg,rgba(255,255,255,0) 0%,rgba(200, 200, 200, 0.25) 100%);
    z-index: 1;
    top: 0;
    margin-left: -30px;
  }

  &::after {
    position: absolute;
    background: ${lightestGray};
    z-index: 2;
    top: 0;
  }
`

export default SidebarContainer
