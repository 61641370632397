import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import DisplayGroupList from './DisplayGroupList'
import onClickOutside from 'react-onclickoutside'

import { placeholder, normalText, textBold, darkGray, red, lightGray, smallText } from '../../Styles/settings/Constants'

const SelectMultiplyWithGroup =
  ({
    label,
    open,
    onClick,
    onClickItem,
    onClickCategory,
    onClickAll,
    onChange,
    items,
    hasError,
    errorMessage,
    values,
    placeholder,
    placeholderSearch,
    allSelected,
    collapseControl,
    notFoundLabel,
    search,
    categoriesState
  }) => {
    SelectMultiplyWithGroup.handleClickOutside = () => onClick(false)

    return (
      <Container>
        <FloatingLabelSelectContainer>
          <Selected open={open} onClick={() => onClick(!open)}>
            {values.length === 0 && placeholder}
            {values.length > 0 && values.length < 2 && (values[0])}
            {values.length > 1 && !allSelected && (`${values.length} itens selecionados`)}
            {allSelected && (`Todos os itens selecionados`)}
          </Selected>
          <Icon
            onClick={() => onClick(!open)}
            src={open ? '/assets/icons/arrow/arrow-up.svg' : '/assets/icons/arrow/arrow-down.svg'}
          />
          {open && (
            <DisplayGroupList
              items={items}
              onClickItem={onClickItem}
              onClickCategory={onClickCategory}
              values={values}
              onClick={onClick}
              onClickAll={onClickAll}
              onChange={onChange}
              placeholderSearch={placeholderSearch}
              allSelected={allSelected}
              collapseControl={collapseControl}
              notFoundLabel={notFoundLabel}
              search={search}
              categoriesState={categoriesState}
            />
          )}
          <FloatingLabel open={open} value={values}>{label}</FloatingLabel>
          <LineAnimation hasError={!open && hasError} />
        </FloatingLabelSelectContainer>
        {hasError && <Error>{errorMessage}</Error>}
      </Container>
    )
  }

const clickOutsideConfig = {
  handleClickOutside: () => SelectMultiplyWithGroup.handleClickOutside
}

SelectMultiplyWithGroup.propTypes = {
  label: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  collapseControl: PropTypes.func,
  onClickItem: PropTypes.func,
  onClickCategory: PropTypes.func,
  onClickAll: PropTypes.func,
  onChange: PropTypes.func,
  items: PropTypes.array,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  values: PropTypes.array,
  placeholder: PropTypes.string,
  placeholderSearch: PropTypes.string,
  notFoundLabel: PropTypes.string,
  allSelected: PropTypes.bool,
  search: PropTypes.string,
  categoriesState: PropTypes.object
}

const Container = styled.article`
  margin-top: 2rem;
`

const FloatingLabelSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 2rem;
  font-size: 2rem;
  border-bottom: ${props => props.disabled ? '0' : '1px'} solid ${lightGray};

  & > * {
    z-index: 2;
  }
`

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${darkGray};
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: ${normalText};
  cursor: text;
  pointer-events: none;

  ${({ open }) => open && css`
    transform: translate3d(0, -95%, 0) scale(0.80);
  `}

  ${({ value }) => value && css`
    transform: translate3d(0, -95%, 0) scale(0.80);
  `}
`

const Selected = styled.label`
  color: ${placeholder};
  width: 100%;
  border: none;
  font-size: ${normalText};
  overflow: auto;
  display: inline-table;
  height: 2.4rem;
  padding-right: 2rem;

  ${({ open }) => open && css`
    font-weight: ${textBold};
    color: ${props => props.theme.color};
  `}
`

const LineAnimation = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${props => props.theme.color};
  transition: 0.4s;

  ${({ hasError }) => hasError && css`
    width: 100%;
    background-color: ${red};
    transition: 0.4s;
  `}
`

const Icon = styled(ReactSVG)`
  position: absolute;
  bottom: 8px;
  right: 4px;
  height: 2rem;
`

const Error = styled.span`
  color: ${red};
  font-size: ${smallText};
  padding-top: 0.6rem;
  display: block;
`

export default onClickOutside(SelectMultiplyWithGroup, clickOutsideConfig)
