import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { textBold } from '../../Styles/settings/Constants'
import { useSelector } from 'react-redux'
import TooltipComponent from '../Filter/TooltipComponent'
import { toMoney } from '../../Utils/Utils'

const SectionComponent = ({ firstField, secondField, isPositive, typeChange }) => {
  const text = `${typeChange == 'all' ? 'Saldo do dia' : typeChange == 'positive' ? 'Total de entradas' : typeChange == 'negative' ? 'Total de saídas' : typeChange == 'agendadas' && 'Total de agendadas'}`

  const { currentBalance, balancesFilters } = useSelector(({ balances }) => {
    return {
      currentBalance: balances.currentBalance,
      balancesFilters: balances.balancesFilters
    }
  })

  if (typeChange == 'all') {
    if (balancesFilters?.shouldHide) {
      return (
        <SectionDiv isPositive={isPositive} typeChange={typeChange}>
          <td className='bold'>{firstField}</td>
          <td />
          <td />
          <td colSpan='2' className='price' style={{ width: '15%' }}>
            <span>Total </span>
            <span className='bold'>{secondField}</span>
          </td>
        </SectionDiv>
      )
    } else if (firstField == 'Hoje') {
      return (
        <SectionDiv isPositive={isPositive} typeChange={typeChange}>
          <td className='bold'>{firstField}</td>
          <td />
          <td />
          <td colSpan='2' className='price' style={{ width: '15%' }}>
            {text} <span className='bold'>{toMoney(currentBalance / 100)}</span>
          </td>
        </SectionDiv>
      )
    } else {
      return (
        <SectionDiv isPositive={isPositive} typeChange={typeChange}>
          <td className='bold'>{firstField}</td>
          <td />
          <td />
          <td colSpan='2' className='price' style={{ width: '15%' }}>
            {text} <span className='bold'>{secondField}</span>
          </td>
        </SectionDiv>
      )
    }
  } else {
    return (
      <SectionDiv isPositive={isPositive} typeChange={typeChange}>
        <td className='bold'>{firstField}</td>
        <td />
        <td />
        <td colSpan='2' className='price' style={{ width: '15%' }}>
          <TooltipComponent id={`tooltip-info${firstField}`} text='O valor total pode ser alterado pelo filtro' className='price' icon>
            {text}
            <span className='bold ml2'>{secondField}</span>
          </TooltipComponent>
        </td>
      </SectionDiv>
    )
  }
}

const SectionDiv = styled.tr`
  width: 100%;

  .bold {
    font-weight: ${textBold};
  }

  color: #222;

  .ml2 {
    margin-left: 0.5em;
  }

  ${({ isPositive }) =>
    isPositive === true
      ? `
      border-top: 1px solid #5BB500;
      background: rgba(91, 181, 0, 0.05) !important;
      .price{
        text-align: right;
        color: #5BB500;
      }
    `
      : `
      border-top: 1px solid #FC3D3D;
      background: rgba(252, 61, 61, 0.05) !important;
      .price{
        text-align: right;
        color: rgba(252, 61, 61, 0.9);
      }
  `}

  ${({ typeChange }) =>
    typeChange == 'agendadas' &&
    `
  border-top: 1px solid rgb(255, 160, 17);
  background: rgba(255, 160, 17, 0.05) !important;
  .price{
    text-align: right;
    color: rgb(255, 160, 17);
  }
`}
`

SectionComponent.propTypes = {
  firstField: PropTypes.string,
  secondField: PropTypes.number,
  isPositive: PropTypes.bool,
  typeChange: PropTypes.string
}

export default SectionComponent
