import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import {
  placeholder,
  white,
  textBold,
  normalText,
  lightGray
} from 'src/Styles/settings/Constants'
import Collapsible from 'react-collapsible'
import { SearchInput } from '../SearchInput'
import ReactSVG from 'react-svg'

const SelectListMerchantCode = ({
  items,
  onSelect,
  onSearchChange,
  collapseControl,
  selected,
  search,
  categoriesState
}) => {
  return (
    <ListContainer>
      <Search
        onChange={onSearchChange}
        hideClear
        placeholder={'Buscar categoria'}
        data-test='merchant-code-search'
        data-tracking='merchant-code-search'
      />
      <Content>
        {items.map((category, index) => {
          const label = category.label && category.label.toLowerCase()
          const options = category.options
          return (
            <>
              <Collapsible
                key={index}
                open={!!search || categoriesState[label]}
                handleTriggerClick={() => collapseControl(label)}
                trigger={
                  <Group data-test={`merchant-code-collapse-${label}`}>
                    {label}
                    <Icon
                      src={
                        !!search || categoriesState[label]
                          ? '/assets/icons/arrow/arrow-up.svg'
                          : '/assets/icons/arrow/arrow-down.svg'
                      }
                    />
                  </Group>
                }
              >
                <List key={label}>
                  {options.map((option) => (
                    <ListItem
                      key={option.id}
                      onClick={(e) => onSelect(e, option)}
                      value={`${option.id}:${option.code}`}
                      selected={option.id === selected}
                      data-test={`merchant-code-item-${option.code}`}
                    >
                      {option.code} | {option.description}
                    </ListItem>
                  ))}
                </List>
              </Collapsible>
            </>
          )
        })}
        {!items.length && (
          <Empty
            title={`Não encontramos essa categoria`}
            description={`Verifique se o nome \nestá correto e tente novamente`}
            iconSrc='/assets/icons/others/search-timeout.svg'
          />
        )}
      </Content>
    </ListContainer>
  )
}

SelectListMerchantCode.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  collapseControl: PropTypes.func,
  selected: PropTypes.string,
  search: PropTypes.string,
  categoriesState: PropTypes.object
}

const ListContainer = styled.div`
  position: absolute;
  background-color: ${white};
  border-radius: 5px;
  top: -3rem;
  left: -1.4rem;
  box-shadow: 0 3px 7px ${lightGray};
  padding: 3rem 1.4rem 4.5rem 1.4rem;
  z-index: 1;
  width: calc(100% + 2.8rem);
  margin-bottom: 2rem;
`

const Empty = styled(EmptyState)`
  width: 100%;
`

const Content = styled.div`
  height: 30rem;
  overflow: auto;
  margin-left: -1.4rem;

  .Collapsible {
    position: relative;
  }
`

const Search = styled(SearchInput)`
  background-color: transparent !important;
  height: 30px;
  margin: 1.4rem 0 1rem 0;
  border-radius: 0;
  border-width: 0;
  border-bottom-width: 1px;
  padding-left: 0 !important;
`

const Group = styled.label`
  font-size: ${normalText};
  color: ${placeholder};
  font-weight: ${textBold};
  padding: 1rem 1.4rem;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize !important;

  &::after {
    position: absolute;
    content: '';
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: calc(100% - 2.8rem);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Icon = styled(ReactSVG)``

const List = styled.ul`
  list-style-type: none;
  padding-left: 1.4rem;
  margin-top: -1rem;
`

const ListItem = styled.li`
  padding: 0.4rem 2.4rem 0.4rem 1.4rem;
  font-size: ${normalText};
  color: ${placeholder};
  margin: 0.4rem -1.4rem 0.4rem 0;
  cursor: pointer;
  position: relative;

  &:hover::after {
    position: absolute;
    content: '';
    display: block;
    background-color: ${(props) => props.theme.color};
    width: 2px;
    height: calc(100% - 8px);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: ${textBold};
    `}
`

export default SelectListMerchantCode
