import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import ReactSVG from 'react-svg'
import { fadeIn } from 'react-animations'

import { darkGray, normalText, biggerText, textBold, white, red } from 'src/Styles/settings/Constants'

const DeniedInfo = ({ handleClose }) => (
  <Container>
    <Info center>
      <TitleMessage>
        {'Este estabelecimento foi\n'}
        <strong>negado</strong>. {'O que pode ter\n'}
        {'acontecido?'}
      </TitleMessage>
    </Info>
    <Info>
      <Text>
        <strong>CPF ou CNPJ irregular</strong>
        {'\nVerifique a situação do CPF ou\n'}
        {'CNPJ junto a receita federal ou\n'}
        {'SPC/SERASA.'}
      </Text>
    </Info>
    <Info>
      <Text>
        <strong>Documentos incompletos</strong>
        {'\nVerifique se os documentos\n'}
        {'enviados estão com boa\n'}
        {'legibilidade e com frente e\n'}
        {'verso'}
      </Text>
    </Info>
    <Info>
      <Text>
        <strong>Não exclua o estabelecimento!</strong>
        {'\nSe todos os itens ao lado\n'}
        {'estiverem OK envie um email\n'}
        {'para suporte@zoop.com.br e\n'}
        {'solicite uma análise'}
      </Text>
    </Info>
    <Close src='/assets/icons/actions/close.svg' onClick={handleClose} />
  </Container>
)

DeniedInfo.propTypes = {
  handleClose: PropTypes.func
}

const fadeInAnimation = keyframes`${fadeIn}`

const Container = styled.div`
  width: 100%;
  height: 190px;
  background-color: ${white};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  padding-right: 42px;
  animation: 0.5s ${fadeInAnimation};
`

const Info = styled.div`
  display: flex;
  justify-content: ${({ center }) => center ? 'center' : 'flex-start'};
  align-items: center;
  flex-direction: column;
  width: 25%;
  background-color: ${white};
  padding: 42px 30px;
  border-radius: 10px;
`

const Text = styled.p`
  color: ${darkGray};
  font-size: ${normalText};
  line-height: 2rem;
  white-space: pre-wrap;

  > strong {
    font-weight: ${textBold};
  }
`

const TitleMessage = styled.p`
  color: ${darkGray};
  font-size: ${biggerText};
  line-height: 2.2rem;
  white-space: pre-wrap;

  > strong {
    font-weight: ${textBold};
    color: ${red};
  }
`

const Close = styled(ReactSVG)`
  position: absolute;
  top: 20px;
  right: 20px;

  &:hover {
    cursor: pointer;
  }
`

export { DeniedInfo }
