import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import Button from 'src/UIComponents/Button/ButtonDefault'

import { white, lightGray, darkerGray, gray60Percent, red, normalText, textBold } from 'src/Styles/settings/Constants'

const StepStatus = ({ children, active, send, radius, denied, onClick }) => {
  const icon = active
    ? '/assets/icons/alerts/success.svg'
    : send
      ? '/assets/icons/others/timeout.svg'
      : ''
  return (
    <Step active={active} radius={radius} denied={denied}>
      <Text active={active || send} denied={denied}>
        {children}
      </Text>
      {denied
        ? <ButtonDenied data-tracking={`SellerDetail > Denied > More`} outline widthAuto colorButton={red} onClick={onClick}>Saiba Mais</ButtonDenied>
        : <Icon src={icon} />
      }
    </Step>
  )
}

StepStatus.propTypes = {
  children: PropTypes.any,
  active: PropTypes.bool,
  send: PropTypes.bool,
  radius: PropTypes.oneOfType(['first', 'last']),
  denied: PropTypes.bool,
  onClick: PropTypes.func
}

const Step = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  background-color: ${white};
  padding: 20px 17px;
  position: relative;

  &:not(:first-child):after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(110% - 26px);
    background: ${lightGray};
    top: 10px;
    left: -1px;
  }

  ${({ active }) => active && css`
    background-color: ${lightGray};

    &:not(:first-child):after {
      background: #B8B8B8;
    }
  `}

  ${({ denied }) => denied && css`
    background-color: ${red};
  `}

  ${({ radius }) => radius && css`
    border-radius: ${radius === 'first' ? '10px 0 0 10px' : '0 10px 10px 0'};
  `}
`

const Text = styled.p`
  color: ${gray60Percent};
  font-size: ${normalText};
  line-height: 2rem;
  white-space: pre-wrap;

  ${({ active }) => active && css`
    color: ${darkerGray};
  `}

  ${({ denied }) => denied && css`
    color: ${white};
  `}

  > strong {
    font-weight: ${textBold};
  }
`

const Icon = styled(ReactSVG)`
  width: 30px;

  svg {
    height: 30px;
    width: 30px;
  }
`

const ButtonDenied = styled(Button)`
  border: 1px solid ${white} !important;
`

export {
  StepStatus
}
