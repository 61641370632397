import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import {
  normalGiantText,
  normalText,
  darkGray,
  spaceMargin,
  smallText
} from 'src/Styles/settings/Constants'

import BadgeComponent from 'src/Components/BadgeComponent/BadgeComponent'
import SellerHeaderInformationLoader from './SellerHeaderInformationLoader'

const SellerHeaderInformationUI = ({ seller, isLoading }) => {
  const document = seller
    ? seller.type === 'individual'
      ? `CPF: ${seller.taxpayer_id.toCPF()}`
      : `CNPJ: ${seller.ein?.toCnpj()}`
    : ''
  const name = seller
    ? seller.type === 'individual'
      ? `${seller.first_name} ${seller.last_name}`
      : `${seller.business_name || ''}`
    : ''

  return (
    <>
      {isLoading ? (
        <SellerHeaderInformationLoader />
      ) : (
        <SellerInformationContainer>
          <>
            <span>Você está no estabelecimento:</span>
            <SellerName>
              {name}
              <BadgeComponent />
            </SellerName>
            <SellerMetaData>
              <span>{document}</span>
              <span>ID: {seller && seller.id}</span>
            </SellerMetaData>
          </>
        </SellerInformationContainer>
      )}
    </>
  )
}

SellerHeaderInformationUI.propTypes = {
  seller: PropTypes.object,
  isLoading: PropTypes.bool
}

const SellerInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    color: ${darkGray};
    margin-bottom: ${spaceMargin};
    font-size: ${smallText};
  }
`
const SellerName = styled.h2`
  color: ${(props) => props.color || props.theme.color};
  font-weight: 700;
  font-size: ${normalGiantText};
`
const SellerMetaData = styled.div`
  span {
    color: ${darkGray};
    font-size: ${normalText};
    margin-right: ${spaceMargin};
  }
`

export default SellerHeaderInformationUI
