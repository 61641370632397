import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes, css } from 'styled-components'
import { fadeIn } from 'react-animations'
import ReactSVG from 'react-svg'

import { placeholder, darkGray, white, normalText, lightGray } from '../../Styles/settings/Constants'

const DisplaySeller = ({ sellerName, open, onClick }) => {
  return (
    <Container open={open} onClick={onClick}>
      <LabelContainer>
        <Selected capitalize={sellerName}>
          {sellerName || 'Todos os estabelecimentos'}
        </Selected>
        <Icon
          src={open ? '/assets/icons/arrow/arrow-up.svg' : '/assets/icons/arrow/arrow-down.svg'}
        />
      </LabelContainer>
      <FloatingLabel open>Você está vendo o estabelecimento</FloatingLabel>
    </Container>
  )
}

DisplaySeller.propTypes = {
  sellerName: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func
}

const fadeAnimation = keyframes`${fadeIn}`

const Container = styled.div`
  width: 100%;
  position: relative;
  border-radius: 5px;
  border: 1px solid ${({ open }) => open ? 'transparent' : lightGray};
  padding: 2rem 4rem 0.4rem 1rem;
  background-color: ${({ open }) => open ? 'none' : white};
  animation: 0.3s ${fadeAnimation};
  cursor: pointer;

  ${({ open }) => open && css`
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 90%;
      border-bottom: 1px solid ${lightGray};
      bottom: -2px;
    }
  `}
`

const LabelContainer = styled.div``

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${darkGray};
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: ${normalText};
  cursor: text;
  pointer-events: none;

  ${({ open }) => open && css`
    transform: translate3d(0, -95%, 0) scale(0.80);
  `}
`

const Selected = styled.label`
  color: ${placeholder};
  width: 100%;
  border: none;
  font-size: ${normalText};
  display: block;
  height: 2.4rem;
  padding-right: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bolder;
  text-transform: ${({ capitalize }) => capitalize ? 'capitalize' : 'none'};
  color: ${({ theme: { color } }) => color};
  cursor: pointer;
`

const Icon = styled(ReactSVG)`
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 3rem;
`

export default DisplaySeller
