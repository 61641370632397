import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { smallerText, darkGray } from '../../Styles/settings/Constants'

const ResultLabel = ({ value }) => (
  <Result>
    {`(${value.toString().withThousandSeparator()} resultados)`}
  </Result>
)

ResultLabel.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ResultLabel

const Result = styled.div`
  white-space: pre;
  display: flex;
  color: ${darkGray};
  font-size: ${smallerText};
`
