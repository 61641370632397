import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'

import { white } from '../../Styles/settings/Constants'
import { SelectListSellerLoader } from '../SelectSeller/SelectListSellerLoader'
import DisplayMarketplaceInfo from './DisplayMarketplaceInfo'

const SelectListMarketplace = ({
  items,
  context,
  currentMarketplace,
  handleSelect,
  isLoading,
  setFavorite,
  deleteFavorite,
  ...props
}) => {
  return (
    <ListContainer>
      <List>
        {isLoading &&
          <Container>
            <SelectListSellerLoader />
          </Container>
        }
        {!isLoading && items.length === 0 &&
          <Container>
            <Empty
              title={`Não encontramos esse\nmarketplace`}
              description={`Verifique se o nome ou CNPJ\nestão corretos e tente novamente`}
              iconSrc='/assets/icons/others/search-timeout.svg'
            />
          </Container>
        }
        {!isLoading && items.length > 0 && items.map((item, index) => (
          <ListItem
            key={index}
            onClick={e => handleSelect(e, item)}
            data-tracking={`${props['data-tracking']} > ${item.isParent ? 'parent' : 'item'}`}
            data-test={`select-marketplace-item-${index}`}
          >
            <DisplayMarketplaceInfo
              active={
                (context &&
                  ((context.marketplace && context.marketplace.id === item.id) ||
                    (!context.marketplace && item.isParent))) ||
                currentMarketplace.id === item.id
              }
              isParent={item.isParent}
              marketplace={item}
              isFavorite={item.isFavorite}
              setFavorite={setFavorite}
              deleteFavorite={deleteFavorite}
            />
          </ListItem>
        ))}
      </List>
    </ListContainer>
  )
}

SelectListMarketplace.propTypes = {
  context: PropTypes.any,
  items: PropTypes.array,
  currentMarketplace: PropTypes.object,
  handleSelect: PropTypes.func,
  isLoading: PropTypes.bool,
  'data-tracking': PropTypes.string,
  setFavorite: PropTypes.func,
  deleteFavorite: PropTypes.func
}

SelectListMarketplace.defaultProps = {
  items: []
}

const ListContainer = styled.div``

const Container = styled.div`
  width: 100%;
  background-color: ${white};
  max-height: 40vh;
`

const Empty = styled(EmptyState)`
  width: 100%;
`

const List = styled.ul`
  max-height: 315px;
  overflow: auto;
  list-style-type: none;
  margin: 0 -1rem;
  width: calc(100% + 1rem);
`

const ListItem = styled.li`
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;

  &:hover::after {
    position: absolute;
    content: '';
    display: block;
    background-color: ${props => props.theme.color};
    width: 2px;
    height: calc(100% - 30px);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`

export default SelectListMarketplace
