import React, { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import Input from 'src/UIComponents/Input/Input'
import IconButton from '../Commons/IconButton/IconButton'

import { white, lightGray, darkGray, spacePadding, smallPadding, smallText, normalText } from 'src/Styles/settings/Constants'

// eslint-disable-next-line no-unused-vars
export const SearchInput = ({ defaultSearch, placeholder, className, onClear, onChange, onSubmit, ...props }) => {
  const [value, setValue] = useState(defaultSearch)

  useEffect(() => {
    setValue(defaultSearch)
  }, [defaultSearch])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(value)
  }

  const handleClear = (e) => {
    e.preventDefault()
    handleChange('')
    onClear()
  }

  const handleChange = (value) => {
    if (value.length === 0) {
      onClear && onClear()
    }

    setValue(value)
    onChange(value)
  }

  return (
    <SearchInputForm data-tracking={`Busca Filtro > Form`} className={className} onSubmit={handleSubmit}>
      <Input
        value={value}
        placeholder={placeholder}
        className={className}
        data-tracking={`Busca Filtro > Input`}
        onChange={(e) => handleChange(e.target.value)}
        data-test='search-input'
      />
      <ActionsContainer>
        {onClear && (
          <ContainerButton>
            <ButtonClear
              data-tracking={`Busca Filtro > Clear`}
              disabled={!value.trim()}
              type='button'
              onClick={handleClear}
              src='/assets/icons/actions/close.svg'
              data-test='search-input-clear-btn'
            >
              limpar
            </ButtonClear>
          </ContainerButton>
        )}
        <Button disabled={!value.trim()} type='submit' onClick={handleSubmit} src='/assets/icons/actions/search.svg' data-test='search-input-search-btn' />
      </ActionsContainer>
    </SearchInputForm>
  )
}

SearchInput.defaultProps = {
  defaultSearch: '',
  placeholder: 'Encontre algo',
  onChange: () => {
    /* noop */
  },
  onSubmit: () => {
    /* noop */
  }
}

SearchInput.propTypes = {
  defaultSearch: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  'data-tracking': PropTypes.string,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
}

export const SearchInputForm = styled.form`
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background-color: ${white};
  border: 1px solid ${lightGray};
  border-radius: 5px;

  ${Input} {
    border: none;
    flex: 1;
    font-size: ${normalText};
    line-height: 20px;
    color: ${darkGray};
    padding: 0.3rem ${spacePadding} 0.3rem ${smallPadding};

    &::placeholder {
      font-style: normal;
      font-size: ${normalText};
      color: ${darkGray};
    }
  }
`

const Button = styled(IconButton)`
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;

  :disabled {
    color: ${lightGray};
    cursor: not-allowed;
  }
`

const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const ButtonClear = styled.button`
  font-size: ${smallText};
  line-height: 16px;
  color: ${darkGray};
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid ${lightGray};
  transition: all ease-in-out 0.2s;
  background: transparent;

  :disabled {
    color: ${lightGray};
    cursor: not-allowed;
  }

  :focus {
    outline: none;
  }
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 1;
  flex: 0;
  height: 100%;
`
