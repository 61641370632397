import React from 'react'
import ContentLoader from 'react-content-loader'

const SelectListSellerLoader = (props) => (
  <ContentLoader
    speed={4}
    width={258}
    height={160}
    viewBox='0 0 300 160'
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    style={{ width: '100%', marginTop: '1rem' }}
    {...props}
  >
    <rect x='10' y='0' rx='5' ry='5' width='280' height='39' />
    <rect x='10' y='50' rx='5' ry='5' width='280' height='39' />
    <rect x='10' y='101' rx='5' ry='5' width='280' height='39' />
  </ContentLoader>
)
export { SelectListSellerLoader }
