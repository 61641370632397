import React, { Fragment } from 'react'
import * as PropTypes from 'prop-types'

import styled from 'styled-components'
import Grid from '../Grid/Grid'
import Button from '../Button/ButtonDefault'
import Label from 'src/UIComponents/Label/Label'
import { toMoney } from 'src/Utils/Utils'

import { normalText, bigText, gray, darkGray, textBold, bigMargin, biggerText } from '../../Styles/settings/Constants'

export const POSMessageSuccess = ({ name }) => (
  <Fragment>
    <Line>
      <Text>POS Habilitado com <Bold>Sucesso!</Bold></Text>
    </Line>
    <Line>
      <SellerName>{name}</SellerName>
    </Line>
  </Fragment>
)

POSMessageSuccess.propTypes = {
  name: PropTypes.string
}

export const AddSellerMessageSuccess = ({ name, onClick }) => (
  <Fragment>
    <Row auto>
      <Text>O estabelecimento</Text>
    </Row>
    <Row auto>
      <SellerName>{name}</SellerName>
    </Row>
    <Row auto>
      <Text>foi adicionado com sucesso!</Text>
    </Row>
    <RowButton auto>
      <Button inversed onClick={() => onClick()} data-tracking='Enviar Documentos' data-test='seller-document-send-btn'>
        Ver detalhe
      </Button>
    </RowButton>
  </Fragment>
)

AddSellerMessageSuccess.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func
}

export const TransactionActionsMessageSuccess = ({ amount, onClick, type }) => {
  const actions = {
    authorize: {
      label1: 'autorizada',
      label2: 'Valor da Autorização'
    },
    refund: {
      label1: 'estornada',
      label2: 'Valor do Estorno'
    },
    cancel: {
      label1: 'cancelada',
      label2: 'Valor do Cancelamento'
    },
    cancelBoleto: {
      label1: 'cancelada',
      label2: 'Valor do Cancelamento'
    }
  }

  const label1 = type === 'cancelBoleto' ? <>Solicitação de <Bold>cancelamento</Bold> enviada ao banco emissor.</> : <>Venda <Bold>{actions[type].label1}</Bold> com sucesso!</>
  const label2 = type === 'cancelBoleto' ? 'O retorno desta solicitação pode demorar até 1 dia útil' : actions[type].label2

  return (
    <Fragment>
      <br />
      <Grid.Row auto>
        <StyledLabel fontSize={bigText} color={darkGray}>{label1}</StyledLabel>
      </Grid.Row>
      <Grid.Row noMargin auto>
        <StyledLabel fontSize={normalText} color={gray}>{label2}</StyledLabel>
      </Grid.Row>
      {amount && <Grid.Row auto>
        <StyledLabel bold fontSize={biggerText} color={darkGray}>{toMoney(amount / 100)}</StyledLabel>
      </Grid.Row>}
      <Grid.Row auto>
        <Button onClick={onClick} inversed white data-test='transaction-detailSale-btn'>Ver Detalhe da Venda</Button>
      </Grid.Row>
    </Fragment>
  )
}

TransactionActionsMessageSuccess.propTypes = {
  amount: PropTypes.number,
  onClick: PropTypes.func,
  type: PropTypes.string
}

export const SendReceiptMessageSuccess = ({ email, onClick }) => {
  return (
    <Fragment>
      <Grid.Row auto>
        <StyledLabel fontSize={bigText} color={darkGray}>Comprovante <Bold>enviado</Bold> com sucesso!</StyledLabel>
      </Grid.Row>
      <Grid.Row auto>
        <StyledLabel textAlign='center' fontSize={normalText} color={gray}>O comprovante chegará em alguns instantes no e-mail: <Bold>{email}</Bold></StyledLabel>
      </Grid.Row>
      <Grid.Row auto>
        <Button inversed onClick={onClick} white data-test='transaction-detailSale-sendReceipt-btn'>Enviar outro Comprovante</Button>
      </Grid.Row>
    </Fragment>
  )
}

SendReceiptMessageSuccess.propTypes = {
  email: PropTypes.string,
  onClick: PropTypes.func
}

const Row = styled(Grid.Row)`
  margin: 0;
`

const RowButton = styled(Grid.Row)`
  margin-top: ${bigMargin};
`

const Text = styled.p`
  color: ${darkGray};
  font-size: ${bigText};
`

const Bold = styled.span`
  font-weight: ${textBold};
`

const Line = styled.div`
  margin-bottom: 10px;
`

const SellerName = styled.p`
  font-size: ${bigText};
  font-weight: ${textBold};
  color: ${darkGray};
  text-transform: capitalize;
  text-align: center;
`

const StyledLabel = styled(Label)`
  display: block;
`
