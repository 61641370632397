import styled, { css } from 'styled-components'
import {
  darkGray,
  lightGray,
  lighterGray,
  lightestGray,
  middlePadding,
  normalText,
  smallPadding,
  smallText,
  darkerGray
} from '../../Styles/settings/Constants'
import EmptyState from '../../UIComponents/EmptyState/EmptyState'
import { PageSizeOptions } from '../../UIComponents/PageSizeOptions/PageSizeOptions'
import Pagination from '../../Components/Pagination/Pagination'
import React from 'react'
import ReactSVG from 'react-svg'
import ResultLabel from '../../UIComponents/Table/ResultLabel'
import TableLoader from './TableLoader'
import { Beacon } from '../../Components/OnboardingHints/Beacon'

const NewTable = ({
  loading,
  tableInstance,
  pageSize,
  pageSizeOptions,
  onResultsPerPageChange,
  totalItems,
  currentPage,
  totalPages,
  onPageChange,
  error,
  emptyState,
  onItemClick,
  dataTracking,
  showPageSizeOptions,
  showPagination,
  manual,
  // eslint-disable-next-line no-unused-vars
  mini,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    state: { pageIndex }
  } = tableInstance

  let items = rows

  if (!manual) {
    items = page
    currentPage = pageIndex
    onPageChange = (value) => {
      gotoPage(value)
    }
  }

  if (loading) {
    return (
      <TableContainer>
        <TableLoaderWrapper>
          <TableLoader />
          <TableLoader />
          <TableLoader />
        </TableLoaderWrapper>
      </TableContainer>
    )
  }

  if (error) {
    const title = error.message.title || 'Alguma coisa aconteceu de errado'
    const description =
      error.message.description ||
      error.message ||
      'Não se preocupe, seus dados não foram afetados. Estamos trabalhando para evitar que se repita.'
    const iconPath = error.message.icon || '/assets/icons/alerts/alert.svg'

    return (
      <TableContainer>
        <EmptyState
          title={title}
          description={description}
          iconColor={darkGray}
          iconSrc={iconPath}
        />
      </TableContainer>
    )
  }

  if (rows && rows.length === 0) {
    return (
      <EmptyState
        title={emptyState.message}
        description={emptyState.description}
        iconColor={darkGray}
        iconSrc={emptyState.icon}
        marginTop='5rem'
      />
    )
  }

  return (
    <TableContainer {...props}>
      {showPageSizeOptions && (
        <TableResultsAndPageSizeContainer>
          <ResultLabel value={totalItems || 0} />
          <PageSizeOptions
            pageSize={pageSize}
            options={pageSizeOptions}
            handleSelect={onResultsPerPageChange}
          />
        </TableResultsAndPageSizeContainer>
      )}
      <table data-test='table' {...getTableProps()}>
        <thead data-test='table-head'>
          {headerGroups.map((headerGroup) => (
            <tr
              data-test='table-head-tr'
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  data-test='table-head-th'
                  {...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    style: {
                      cursor: column.sortable ? 'pointer' : 'auto',
                      width: column.width,
                      borderBottom:
                        column.sortable && column.isSorted
                          ? '2px solid black'
                          : 'none'
                    }
                  })}
                >
                  <span
                    data-test='table-head-th-span'
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {column.render('Header')}
                    {column.isSorted && column.sortable ? (
                      column.isSortedDesc ? (
                        <ReactSVG src='/assets/icons/arrow/mini-arrow-up.svg' />
                      ) : (
                        <ReactSVG src='/assets/icons/arrow/mini-arrow-down.svg' />
                      )
                    ) : (
                      ''
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-test='table-body' {...getTableBodyProps()}>
          {items.map((row, rowIndex) => {
            prepareRow(row)
            return (
              <>
                {row.original.type === 'subHeader' ? (
                  <SubHeaderTr>
                    <td data-test='table-body-subheader-td-5' colSpan='5'>
                      {row.original.title}
                    </td>
                    <td data-test='table-body-subheader-td-2' colSpan='2'>
                      {row.original.totalAmount}
                    </td>
                  </SubHeaderTr>
                ) : (
                  <tr
                    data-test='table-body-tr'
                    {...row.getRowProps({
                      onClick: onItemClick
                        ? (e) => onItemClick(e, row.original)
                        : null,
                      style: {
                        cursor: onItemClick ? 'pointer' : 'auto'
                      },
                      'data-tracking': `${dataTracking} > Detail`,
                      name: 'tableRow'
                    })}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          data-test='table-body-td'
                          {...cell.getCellProps({
                            style: {
                              width: cell.column.width
                            }
                          })}
                        >
                          {cell.render('Cell')}
                          {rowIndex === 0 && index === 1 && (
                            <Beacon hint='copie-cole' />
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )}
              </>
            )
          })}
        </tbody>
      </table>
      {showPagination && (
        <PaginationContainer>
          <Pagination
            page={currentPage}
            pages={totalPages}
            onPageChange={onPageChange}
          />
        </PaginationContainer>
      )}
    </TableContainer>
  )
}

export default NewTable

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0 none;
  box-shadow: none;
  font-size: ${normalText};

  table {
    table-layout: fixed;
    overflow-wrap: break-word;
    margin-top: 10px;
    width: 100%;
  }

  tr {
    display: table-row !important;
  }

  thead {
    color: ${darkerGray};
    border-bottom: solid 1px ${lightGray};

    th {
      font-weight: normal;
      font-size: ${smallText};
      padding: ${middlePadding};
    }
  }

  tbody {
    &:last-child {
      border-bottom: none;
    }

    tr {
      align-items: center;
    }

    td {
      height: ${({ heightRow }) => (heightRow ? '75px' : 'auto')};
      padding: ${({ fitRow }) =>
        fitRow ? `0 ${smallPadding}` : middlePadding};

      ${({ mini }) =>
        mini &&
        css`
          padding: ${smallPadding} 0;
        `}
    }

    ${({ chessBackground }) =>
      chessBackground &&
      css`
        tr:nth-child(even) {
          border-radius: 1rem;
          background-color: ${lighterGray};
        }
      `}
  }

  .loader-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);

    .loader-container {
      height: unset;
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }
`

const TableResultsAndPageSizeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: right;
`

const TableLoaderWrapper = styled.div`
  background-color: ${lightestGray};
`

const SubHeaderTr = styled.tr`
  background-color: #ebebeb;
  display: block !important;
  height: 4rem !important;

  td:first-child {
    text-align: center;
  }
`
