import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import ReactSvg from 'react-svg'

import Grid from '../Grid/Grid'
import Button from '../Button/ButtonDefault'
import Card from '../Card/Card'

import { modalSuccessMargin } from '../../Styles/settings/Constants'

const SuccessCard = ({ textButton, onClick, children, noPadding, sizeButton }) => (
  <Grid noPadding>
    <Container noPadding={noPadding}>
      <Grid.Col offset={1} cols={14}>
        <CardContainer>
          <Icon src='/assets/icons/alerts/success-green.svg' />
          {children}
        </CardContainer>
      </Grid.Col>
    </Container>
    {textButton &&
      <Wrapper>
        <ButtonContainer sizeButton={sizeButton}>
          <Button onClick={() => onClick()} data-test='transaction-success-card-btn'>
            {textButton}
          </Button>
        </ButtonContainer>
      </Wrapper>
    }
  </Grid>
)

SuccessCard.propTypes = {
  onClick: PropTypes.func,
  textButton: PropTypes.string,
  children: PropTypes.element,
  noPadding: PropTypes.bool,
  sizeButton: PropTypes.string
}

const Container = styled(Grid.Row)`
  padding-top: ${modalSuccessMargin};

  ${({ noPadding }) => noPadding && css`
    padding: 0;
  `}
`

const Icon = styled(ReactSvg)`
  position: absolute;
  top: -25px;
`

const CardContainer = styled(Card)`
  padding-top: 3rem;
  position: relative;
`

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const ButtonContainer = styled.div`
  width: ${({ sizeButton }) => sizeButton || '100%'};
`

export default SuccessCard
