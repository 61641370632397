import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'
import SelectListMerchantCode from './SelectListMerchantCode'

import { placeholder, normalText, textBold, darkGray, red, lightGray, smallText } from '../../Styles/settings/Constants'

const SelectMerchantCode = ({
  label,
  open,
  onClick,
  onSelect,
  onSearchChange,
  items,
  selected,
  hasError,
  errorMessage,
  value,
  categoriesState,
  collapseControl,
  search
}) => (
  <Container>
    <SelectContainer>
      <Selected open={open} onClick={() => onClick()} data-test='merchant-code-select'>
        {value.length === 0 && 'Selecione a categoria'}
        {value.length > 0 && value}
      </Selected>
      <Icon
        onClick={() => onClick()}
        src={open ? '/assets/icons/arrow/arrow-up.svg' : '/assets/icons/arrow/arrow-down.svg'}
      />
      {open && (
        <SelectListMerchantCode
          items={items}
          onSelect={onSelect}
          onClick={onClick}
          onSearchChange={onSearchChange}
          selected={selected}
          collapseControl={collapseControl}
          categoriesState={categoriesState}
          search={search}
        />
      )}
      <FloatingLabel open>{label}</FloatingLabel>
      <LineAnimation hasError={!open && hasError} />
    </SelectContainer>
    {hasError && <Error data-test='merchant-code-error' >{errorMessage}</Error>}
  </Container>
)

SelectMerchantCode.propTypes = {
  label: PropTypes.string,
  open: PropTypes.bool,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  items: PropTypes.array,
  selected: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  categoriesState: PropTypes.object,
  collapseControl: PropTypes.func,
  search: PropTypes.string
}

const Container = styled.div`
  margin-top: 2rem;
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  font-size: 2rem;
  border-bottom: ${props => props.disabled ? '0' : '1px'} solid ${lightGray};

  & > * {
    z-index: 2;
  }
`

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  color: ${darkGray};
  bottom: 0;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: ${normalText};
  cursor: text;
  pointer-events: none;

  ${({ open }) => open && css`
    transform: translate3d(0, -95%, 0) scale(0.80);
  `}
`

const LineAnimation = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: ${props => props.theme.color};
  transition: 0.4s;

  ${({ open }) => open && css`
    width: 50%;
    transition: 0.4s;
  `}

  ${({ hasError }) => hasError && css`
    width: 100%;
    background-color: ${red};
    transition: 0.4s;
  `}
`

const Selected = styled.label`
  color: ${placeholder};
  width: 100%;
  border: none;
  font-size: ${normalText};
  overflow: auto;
  display: block;
  height: 2.4rem;
  padding-right: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ open }) => open && css`
    font-weight: ${textBold};
    color: ${props => props.theme.color};
  `}
`

const Icon = styled(ReactSVG)`
  position: absolute;
  bottom: 0;
  right: 4px;
  height: 3rem;
`

const Error = styled.span`
  color: ${red};
  font-size: ${smallText};
  margin-top: 4px;
  display: flex;
  line-height: 16px;
`

export default SelectMerchantCode
