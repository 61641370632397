import {
  darkGray,
  lightGray,
  lighterGray,
  lightestGray,
  middlePadding,
  normalText,
  smallPadding,
  smallText
} from 'src/Styles/settings/Constants'
import styled, { css } from 'styled-components'

import EmptyState from '../../UIComponents/EmptyState/EmptyState'
import { PageSizeOptions } from '../../UIComponents/PageSizeOptions/PageSizeOptions'
import Pagination from '../../Components/Pagination/Pagination'
import React from 'react'
import ReactSVG from 'react-svg'
import ResultLabel from '../../UIComponents/Table/ResultLabel'
import TableLoader from './TableLoader'
import SectionComponent from '../Section/Section'
import { momentCheckIfTodayOrYesterday, toMoney } from '../../Utils/Utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { darkerGray, green, yellow } from '../../Styles/settings/Constants'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import createToast from '../../Utils/createToast'
import { Tooltip } from '../Tooltip'

const SectionedTable = ({
  loading,
  tableInstance,
  pageSize,
  pageSizeOptions,
  onResultsPerPageChange,
  totalItems,
  currentPage,
  totalPages,
  onPageChange,
  error,
  emptyState,
  onItemClick,
  dataTracking,
  showPageSizeOptions,
  showPagination,
  manual,
  // eslint-disable-next-line no-unused-vars
  mini,
  isBanking,
  typeChange,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    state: { pageIndex }
  } = tableInstance

  let items = rows

  const { seller: sellerContext } = useSelector(({ context }) => context)
  const history = useHistory()

  if (!manual) {
    items = page
    currentPage = pageIndex
    onPageChange = (value) => {
      gotoPage(value)
    }
  }

  if (loading) {
    return (
      <TableContainer>
        <TableLoaderWrapper>
          <TableLoader />
          <TableLoader />
          <TableLoader />
        </TableLoaderWrapper>
      </TableContainer>
    )
  }

  if (error) {
    const title = error.message.title || 'Alguma coisa aconteceu de errado'
    const description =
      error.message.description ||
      error.message ||
      'Não se preocupe, seus dados não foram afetados. Estamos trabalhando para evitar que se repita.'
    const iconPath = error.message.icon || '/assets/icons/alerts/alert.svg'

    return (
      <TableContainer>
        <EmptyState
          title={title}
          description={description}
          iconColor={darkGray}
          iconSrc={iconPath}
        />
      </TableContainer>
    )
  }

  if (rows && rows.length === 0) {
    return (
      <EmptyState
        title={emptyState.message}
        description={emptyState.description}
        iconColor={darkGray}
        iconSrc={emptyState.icon}
        marginTop='5rem'
      />
    )
  }

  const handleDetailsFunction = (event, row) => {
    if (!isBanking) {
      onItemClick(event, row.original)
    } else if (
      (row.original.entry_type === 'billing_payment' ||
        row.original.entry_type === 'billing_payment_refunded') &&
      row.original.authorization_id
    ) {
      history.push(
        `/estabelecimento/${sellerContext.id}/extrato/pagamento-de-conta/${row.original.authorization_id}`
      )
    } else {
      createToast(
        'error',
        'Essa transação não é do tipo pagamento de conta, ou não possui o authorization_id'
      )
      return null
    }
  }

  return (
    <TableContainer {...props}>
      {showPageSizeOptions && (
        <TableResultsAndPageSizeContainer>
          <ResultLabel value={totalItems || 0} />
          <PageSizeOptions
            pageSize={pageSize}
            options={pageSizeOptions}
            handleSelect={onResultsPerPageChange}
          />
        </TableResultsAndPageSizeContainer>
      )}
      <table data-test='table' {...getTableProps()}>
        <thead data-test='table-head'>
          {headerGroups.map((headerGroup) => (
            <tr
              data-test='table-head-tr'
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => (
                <>
                  {index === 1 && (
                    <th
                      style={{
                        width: column.width * 2
                      }}
                    />
                  )}
                  <th
                    data-test='table-head-th'
                    {...column.getHeaderProps({
                      style: {
                        cursor: column.sortable ? 'pointer' : 'auto',
                        width: column.width,
                        borderBottom:
                          column.sortable && column.isSorted
                            ? '2px solid black'
                            : 'none'
                      }
                    })}
                  >
                    <span
                      data-test='table-head-th-span'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                      }}
                    >
                      {column.render('Header')}
                      {column.isSorted && column.sortable ? (
                        column.isSortedDesc ? (
                          <ReactSVG src='/assets/icons/arrow/mini-arrow-up.svg' />
                        ) : (
                          <ReactSVG src='/assets/icons/arrow/mini-arrow-down.svg' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody data-test='table-body' {...getTableBodyProps()}>
          {items.map((row) => {
            prepareRow(row)
            return (
              <>
                {row.original?.first ? (
                  <SectionComponent
                    firstField={momentCheckIfTodayOrYesterday(
                      row.original.date,
                      true
                    )}
                    secondField={
                      typeChange === 'agendadas'
                        ? row.original.blocked_balance
                        : row.original.balance
                    }
                    isPositive={row.original.positive}
                    typeChange={typeChange}
                  />
                ) : (
                  <>
                    {row.original.type === 'subHeader' ? (
                      <SubHeaderTr>
                        <td data-test='table-body-subheader-td-5' colSpan='5'>
                          {row.original.title}
                        </td>
                        <td data-test='table-body-subheader-td-2' colSpan='2'>
                          {row.original.totalAmount}
                        </td>
                      </SubHeaderTr>
                    ) : (
                      <tr
                        className='items'
                        data-test='table-body-tr'
                        {...row.getRowProps({
                          onClick: (event) => handleDetailsFunction(event, row),
                          style: {
                            cursor: onItemClick ? 'pointer' : 'auto'
                          },
                          'data-tracking': `${dataTracking} > Detail`,
                          name: 'tableRow'
                        })}
                      >
                        {row.cells.map((cell, index) => {
                          return (
                            <>
                              {index === 1 && (
                                <td
                                  style={{
                                    width: cell.column.width
                                  }}
                                />
                              )}
                              <td
                                className={
                                  cell.row.original?.amount >= 0
                                    ? 'positive__price'
                                    : 'negative__price'
                                }
                                data-test='table-body-td'
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.width
                                  }
                                })}
                              >
                                <div
                                  className={`${index === 2 ? 'price__container__balance' : ''}`}
                                >
                                  {index === 2 &&
                                  cell.row.original?.blocked_amount > 0 ? (
                                    <LabelWithTooltip
                                      className='blocked__clock'
                                      data-tip
                                      data-for='tooltip-blocked-amount'
                                    >
                                      <span className='blocked__price'>
                                        {toMoney(
                                          cell.row.original?.blocked_amount
                                        )}
                                      </span>
                                      <FontAwesomeIcon
                                        fixedWidth
                                        className='blocked__clock__icon'
                                        icon={faClock}
                                        color={yellow}
                                      />
                                      <Tooltip id='tooltip-blocked-amount'>
                                        Valor agendado
                                      </Tooltip>
                                    </LabelWithTooltip>
                                  ) : (
                                    <>{cell.render('Cell')}</>
                                  )}
                                </div>
                              </td>
                            </>
                          )
                        })}
                      </tr>
                    )}
                  </>
                )}
              </>
            )
          })}
        </tbody>
      </table>
      {showPagination && (
        <PaginationContainer>
          <Pagination
            page={currentPage}
            pages={totalPages}
            onPageChange={onPageChange}
          />
        </PaginationContainer>
      )}
    </TableContainer>
  )
}

export default SectionedTable

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border: 0 none;
  box-shadow: none;
  font-size: ${normalText};

  table {
    table-layout: fixed;
    overflow-wrap: break-word;
    margin-top: 10px;
    width: 100%;
  }

  tr {
    display: table-row !important;

    th:nth-last-child(2) {
      span {
        display: block !important;
        text-align: right;
      }
    }
  }

  .price__container__balance {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .blocked__price {
    color: ${yellow} !important;
    margin-left: 5px;
  }

  .blocked__clock {
    padding-left: 8px;
    margin-right: -20px;
  }

  .blocked__clock__icon {
    margin-top: 2px;
  }

  .positive__price:nth-last-child(2) {
    span {
      color: ${green};
    }
  }

  .negative__price:nth-last-child(2) {
    span {
      color: rgba(252, 61, 61, 0.9);
    }
  }

  thead {
    color: ${darkerGray};
    border-bottom: solid 1px ${lightGray};

    th {
      font-weight: normal;
      font-size: ${smallText};
      padding: ${middlePadding};
    }
  }

  .items {
    td:first-child {
      font-weight: bold;
    }
  }

  tbody {
    &:last-child {
      border-bottom: none;
    }

    tr {
      align-items: center;

      td:nth-last-child(2) {
        span {
          text-align: right;
        }
      }
    }

    td {
      height: ${({ isBanking }) => (isBanking ? '75px' : 'auto')};
      padding: ${({ fitRow }) =>
        fitRow ? `0 ${smallPadding}` : middlePadding};

      ${({ mini }) =>
        mini &&
        css`
          padding: ${smallPadding} 0;
        `}
    }

    ${({ chessBackground }) =>
      chessBackground &&
      css`
        tr:nth-child(even) {
          border-radius: 1rem;
          background-color: ${lighterGray};
        }
      `}
  }

  .loader-wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);

    .loader-container {
      height: unset;
      position: fixed;
      top: 50%;
      left: 50%;
    }
  }
`

const TableResultsAndPageSizeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: right;
`

const TableLoaderWrapper = styled.div`
  background-color: ${lightestGray};
`

const SubHeaderTr = styled.tr`
  background-color: #ebebeb;
  display: block !important;
  height: 4rem !important;

  td:first-child {
    text-align: center;
  }
`

const LabelWithTooltip = styled.div`
  display: flex;
`
