import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Collapsible from 'react-collapsible'
import ReactSVG from 'react-svg'

import CheckboxForm from '../Checkbox/CheckboxForm'
import { SearchInput } from '../SearchInput'
import Grid from '../Grid/Grid'
import Button from 'src/UIComponents/Button/ButtonDefault'

import { smallPadding, lightGray, white, darkGray, darkerGray, normalText, textBold } from '../../Styles/settings/Constants'

const DisplayGroupList = (
  {
    items,
    onClickItem,
    onClickCategory,
    onClick,
    onClickAll,
    values,
    onChange,
    placeholderSearch,
    allSelected,
    collapseControl,
    notFoundLabel,
    search,
    categoriesState
  }) => {
  return (
    <DisplayContainer>
      <Search onChange={onChange} hideClear placeholder={placeholderSearch} />
      <ListContainer>
        <ContainerList noLine>
          <CheckboxForm id='all' checked={allSelected} label='Selecionar tudo' onClick={(e) => onClickAll(e, items)} />
        </ContainerList>
        {items.length === 0 && (
          <ContainerList>
            <Label>{notFoundLabel}</Label>
          </ContainerList>
        )}
        {items.length > 0 && items.map((category, index) => {
          const { options, label } = category
          const anyCategoryOptionsSelected = options.find(option => values.includes(option.value))
          const allCategoryOptionsSelected = options.filter(option => values.includes(option.value)).length === options.length

          return (
            <Collapsible
              key={index}
              handleTriggerClick={() => collapseControl(label)}
              open={!!search || categoriesState[label]}
              transitionTime={200}
              trigger={
                <ContainerList data-test={`select_multiply-collapse-${index}`}>
                  <Grid.Row>
                    <Grid.Col cols={14}>
                      <CheckboxForm
                        id={category.label}
                        checked={allCategoryOptionsSelected}
                        partialChecked={!allCategoryOptionsSelected && !!anyCategoryOptionsSelected}
                        label={category.label}
                        onClick={e => onClickCategory(e, items, allCategoryOptionsSelected, options, label)}
                      />
                    </Grid.Col>
                    <Grid.Col cols={2}>
                      <Icon
                        src={search ? '' : categoriesState[label] ? '/assets/icons/arrow/arrow-up.svg' : '/assets/icons/arrow/arrow-down.svg'} />
                    </Grid.Col>
                  </Grid.Row>
                </ContainerList>
              }
            >
              <List>
                {options.map((option, key) => (
                  <ListItem key={key} data-test={`select_multiply-list-${key}`}>
                    <CheckboxForm
                      id={option.label}
                      checked={values.includes(option.value)}
                      label={option.label}
                      onClick={(e) => onClickItem(e, items, option.value)}
                    />
                  </ListItem>
                ))}
              </List>
            </Collapsible>
          )
        })}
      </ListContainer>
      <Footer>
        <Button type='button' color={darkerGray} link onClick={(e) => onClickAll(e, [])} data-test='select_multiply-clear-btn'>Limpar tudo</Button>
        <Button link onClick={() => onClick(false)} data-test='select_multiply-select-btn'>Selecionar</Button>
      </Footer>
    </DisplayContainer>
  )
}

DisplayGroupList.propTypes = {
  items: PropTypes.array,
  onClickItem: PropTypes.func,
  onClickCategory: PropTypes.func,
  onClick: PropTypes.func,
  onClickAll: PropTypes.func,
  onChange: PropTypes.func,
  values: PropTypes.array,
  placeholderSearch: PropTypes.string,
  notFoundLabel: PropTypes.string,
  allSelected: PropTypes.bool,
  collapseControl: PropTypes.func,
  search: PropTypes.string,
  categoriesState: PropTypes.object
}

const DisplayContainer = styled.div`
  position: absolute;
  background-color: ${white};
  border-radius: 0 0 5px 5px;
  z-index: 1000;
  top: 3rem;
  left: 0;
  padding-bottom: 4.5rem;
  padding-top: 3rem;
  box-shadow: 0 3px 7px ${lightGray};
  top: -3rem;
  padding: 4.5rem 1.4rem 1.4rem;
  z-index: 1!important;
  left: -1.4rem;
  width: calc(100% + 2.8rem);
`

const ListContainer = styled.div`
  max-height: 360px;
  overflow: auto;
  padding: 0;
`

const List = styled.ul`
  list-style-type: none;
  border-top: 1px solid ${lightGray};
  padding: ${smallPadding} 0;

  ${({ noLine }) => noLine && css`
    border-top: 0;
  `}
`

const ContainerList = styled.div`
  border-top: 1px solid ${lightGray};
  padding: ${smallPadding} 0;

  ${({ noLine }) => noLine && css`
    border-top: 0;
  `}
`

const ListItem = styled.li`
  width: 100%;
  padding: 0.2rem 0 0.2rem 1.2rem;
  list-style-type: none;
`

const Search = styled(SearchInput)`
  background-color: transparent!important;
  height: 30px;
  margin: 0 0 1rem;
  margin-top: 1.4rem;
  border-radius: 0;
  border-width: 0;
  border-bottom-width: 1px;
  padding-left: 0 !important;
`

const Label = styled.label`
  color: ${darkGray};
  font-size: ${normalText};
  font-weight: ${textBold};
`

const Icon = styled(ReactSVG)``

const Footer = styled.div`
  width: calc(100% + 2.6rem);
  height: 45px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid ${lightGray};
  display: flex;
  justify-content: space-between;
  margin: 0 -1.4rem -1.4rem;
  position: relative;

  & > button {
    width: auto;
    padding: 0 2rem;
  }
`

export default DisplayGroupList
