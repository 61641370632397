import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

import Grid from 'src/UIComponents/Grid/Grid'
import Title from 'src/UIComponents/Title/Title'
import { StepStatus } from './StepStatus'
import { DeniedInfo } from './DeniedInfo'

import { white } from 'src/Styles/settings/Constants'

const StepStatusBySeller = ({ active, send, denied }) => {
  const [deniedInfo, setDeniedInfo] = useState(false)

  if (deniedInfo) {
    return (
      <Wrapper>
        <Grid.Row auto largeMarginBottom>
          <Title bar small><strong>Acompanhe</strong> o processo de credenciamento</Title>
        </Grid.Row>
        <DeniedInfo handleClose={() => setDeniedInfo(false)} />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <Grid.Row auto>
        <Title bar small><strong>Acompanhe</strong> o processo de credenciamento</Title>
      </Grid.Row>
      <Container>
        <StepStatus active={active} radius='first'>
          {'Preenchimento dos\n'}
          <strong>dados cadastrais</strong>
        </StepStatus>
        <StepStatus send={active} active={active && send}>
          Envio de <strong>documentos</strong>
          {'\nde identificação'}
        </StepStatus>
        <StepStatus send={active && send} active={denied}>
          Análise da <strong>situação fiscal</strong>
          {'\ndo estabelcimento'}
        </StepStatus>
        <StepStatus radius='last' denied={denied} onClick={() => setDeniedInfo(true)}>
          {denied
            ? <>
              {'Cadastro\n'}
              <strong>NEGADO</strong>
            </>
            : <>
              Atualização do <strong>status</strong>
              {'\nde cadastro'}
            </>
          }
        </StepStatus>
      </Container>
    </Wrapper>
  )
}

StepStatusBySeller.propTypes = {
  active: PropTypes.bool,
  send: PropTypes.bool,
  denied: PropTypes.bool
}

const fadeInAnimation = keyframes`${fadeIn}`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 6rem 0 5rem 0;
`

const Container = styled.div`
  width: 100%;
  height: 75px;
  background-color: ${white};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  animation: 0.5s ${fadeInAnimation};
`

export { StepStatusBySeller }
