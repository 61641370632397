import React, { memo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import EmptyState from 'src/UIComponents/EmptyState/EmptyState'
import {
  placeholder,
  white,
  textBold,
  normalText,
  lightGray,
  darkGray
} from '../../Styles/settings/Constants'
import { SearchInput } from '../SearchInput'

const SelectListBankCode = memo(
  ({ items, onSelect, onSearchChange, selected }) => {
    const byValue = (a, b) => a.value - b.value
    const shortcuts = items.filter((i) => i.mostUsed).sort(byValue)
    return (
      <ListContainer>
        <Search onChange={onSearchChange} hideClear placeholder={'Buscar banco'} />
        <Content>
          {items.length > 0 && (
            <>
              {shortcuts.length > 0 && (
                <BrandList>
                  <Label>Bancos mais usados</Label>
                  {shortcuts.map((item) => (
                    <Icon
                      key={item.id}
                      onClick={() => onSelect(item.code)}
                      src={`/assets/icons/shortcutBanks/${item.icon.replace(
                        'png',
                        'svg'
                      )}`}
                    />
                  ))}
                </BrandList>
              )}
              <List>
                <Label>Todos os bancos</Label>
                {items.sort(byValue).map((item) => (
                  <ListItem
                    key={item.id}
                    data-test={`select-bank-item-${item.code}`}
                    onClick={() => onSelect(item.code)}
                    selected={item.code === selected}
                  >
                    {`${item.code} - ${item.name}`}
                  </ListItem>
                ))}
              </List>
            </>
          )}
          {!items.length && (
            <Empty
              title={`Não encontramos esse banco`}
              description={`Verifique se o nome \nestá correto e tente novamente`}
              iconSrc='/assets/icons/others/search-timeout.svg'
            />
          )}
        </Content>
      </ListContainer>
    )
  }
)

SelectListBankCode.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  onSearchChange: PropTypes.func,
  selected: PropTypes.string
}

const Label = styled.label`
  color: ${darkGray};
  font-size: 1.1rem;
`

const Icon = styled(ReactSVG)`
  cursor: pointer;
`

const ListContainer = styled.div`
  position: absolute;
  background-color: ${white};
  border-radius: 5px;
  z-index: 1000;
  top: 3rem;
  left: 0;
  padding-bottom: 4.5rem;
  padding-top: 3rem;
  box-shadow: 0 3px 7px ${lightGray};
  top: -3rem;
  padding: 4.5rem 1.4rem 1.4rem;
  z-index: 1 !important;
  left: -1.4rem;
  width: calc(100% + 2.8rem);
  margin-bottom: 2rem;
`

const Empty = styled(EmptyState)`
  width: 100%;
`

const Content = styled.div`
  height: 30rem;
  overflow: auto;
  margin-left: -1.4rem;
  padding-left: 1.4rem;
`

const Search = styled(SearchInput)`
  background-color: transparent !important;
  height: 30px;
  margin: 0 0 1rem;
  margin-top: 1.4rem;
  border-radius: 0;
  border-width: 0;
  border-bottom-width: 1px;
  padding-left: 0 !important;
`

const List = styled.ul`
  list-style-type: none;
  padding-left: 0px;
`

const BrandList = styled.ul`
  list-style-type: none;
  padding-left: 0px;
  display: grid;
  margin: 1rem 0;
  grid-gap: 1rem;
  grid-template:
    'label label label label'
    '. . . .';
  grid-template-columns: fit-content(25%) fit-content(25%) fit-content(25%) fit-content(
      25%
    );

  ${Label} {
    grid-area: label;
  }
`

const ListItem = styled.li`
  padding: 0.3rem 1.4rem;
  font-size: ${normalText};
  color: ${placeholder};
  margin: 1rem 0;
  margin-left: -1.4rem;
  cursor: pointer;
  position: relative;

  &:hover::after {
    position: absolute;
    content: '';
    display: block;
    background-color: ${props => props.theme.color};
    width: 2px;
    height: calc(100% - 8px);
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  ${({ selected }) =>
    selected &&
    css`
      font-weight: ${textBold};
    `}
`

export default SelectListBankCode
